import {Gtin} from '../../shared/models/gtin.model';
import {FormInputError} from './formInputError';
import {Allergen} from './Allergen.model';
import {Certifications} from './certifications.model';
import {ProductFormModel} from './productCreationForm.model';
import {NetContent} from './NetContent.model';
import {ImageUrl} from './ImageUrl.model';
import {ProductName} from './ProductName.model';
import {Nutriment} from './nutriment.model';

export class VcsProductFormModel extends ProductFormModel {
    constructor(
        public gtin: Gtin,
        public cipError: FormInputError,
        public alcoholDegreeError: FormInputError,
        public volumeError: FormInputError,
        public mpn: string,
        public sku: string,
        public brand: string,
        public subBrand: string,
        public name: string,
        public productNames: ProductName[],
        public imagesToUpload: Object[],
        public description: string,
        public webLink: string,
        public completionLevel: number,
        public companyId?: number,
        public targetMarket: string[] = ['250'],
        public codeGpc: string = '',
        public spiritCodeGpc: string = '',
        public alcoholDegree: string = '',
        public productCountry: string = '',
        public wineColor: string = '',
        public vintage?: number,
        public ageOfSpirit: string = '',
        public allergen?: Allergen,
        public vintageName: string = '',
        public container: string = '',
        public certifications: Certifications = {},
        public varietals: string[] = [],
        public sugarContent: string = '',
        public aoc: string = '',
        public spiritStyle: string = '',
        public gis: string = '',
        public isPrivate: boolean = false,
        public category: string = '',
        public netContents: NetContent[] = [new NetContent('', 'CLT')],
        public vbGs1CompletionLevel?: number,
        public adhereToTheSectoralAgreement?: boolean,
        public imagesAddedByUrl?: ImageUrl[],
        public productResellerType?: string,
        public ingredientStatement: string = '',
        public preparationStateCode: string = '',
        public nutriment: Nutriment = {},
        public adhereToRegulatoryAgreement?: boolean,
        public originGtin: string = ''
    ) {
        super(gtin, cipError, mpn, sku, brand, subBrand, name, productNames, imagesToUpload, description, webLink, completionLevel,
            companyId,
            targetMarket,
            codeGpc,
            isPrivate, category, netContents, adhereToTheSectoralAgreement, vbGs1CompletionLevel, imagesAddedByUrl, productResellerType, originGtin);
    }
}
