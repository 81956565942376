import {
  Component, ViewChild, ElementRef, OnInit, Output, EventEmitter, Input, OnDestroy, AfterViewInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModal } from '../../../../shared/components/index';
import { NotificationsSvc, ProductCategorySvc } from '../../../../shared/services/index';
import { BarcodeGenerationSvc, ProductsSvc } from '../../../services/index';
import { CompanySvc } from '../../../../shared/services/company.service';
import { FormInputError } from '../../../models/formInputError';
import { DashboardConst } from '../../../models/dashboard.constants';
import URL from '../../../../shared/utils/url';
import { FeatureToggleSvc } from '../../../../shared/services/feature-toggle.service';
import { Classification } from '../../../../shared/models/Classification.model';
import { VintageSvc } from '../../../../shared/services/vintages.service';
import { AgeOfSpiritModel } from '../../../models/ageOfSpirit.model';
import { containerCodes } from '../../../models/container.constants';
import { Varietal } from '../../../models/varietals.constants';
import { Aoc } from '../../../models/aoc.constants';
import { GisCodes } from '../../../models/gis.constants';
import { VcsEditionUnityForm } from '../../../models/vcsEditionUnityForm.model';
import GtinUtils from '../../../../shared/utils/gtin';
import { NetContent } from '../../../models/NetContent.model';

import { Observable, Subscription } from 'rxjs';
import { DashboardHeaderSvc } from '../../../services/dashboard-header.service';

import * as _ from 'lodash';
import { NutrimentItem } from '../../../models/nutrimentItem.model';
import { optionalNutrimentItemsConst } from '../../../models';
import { Nutriment } from '../../../models/nutriment.model';


@Component({
  selector: 'unity-consumer-edition-vcs',
  styleUrls: ['./unity-consumer-edition-vcs.less'],
  templateUrl: './unity-consumer-edition-vcs.tpl.html'
})
export class UnityConsumerEditionVcsComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('confirmationModal', { static: true })
  public confirmationModal: ConfirmationModal;
  public constants = DashboardConst;
  @Input() public product: any;
  @Input() public isWine: boolean;
  @Input() public isSpi: boolean;
  @Input() public unityForm: VcsEditionUnityForm;
  @ViewChild('formProduct', { static: true })
  public form;
  public webLinkError: FormInputError;
  public multiSelectConfig: any = Object.assign({}, {
    highlight: false,
    create: false,
    persist: true,
    plugins: ['remove_button'],
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: ['wording'],
    maxItems: 15
  });
  public aocSelectConfig: any = Object.assign({}, {
    openOnFocus: false,
    highlight: false,
    create: false,
    persist: true,
    dropdownDirection: 'down',
    wrapperClass: 'gs1selectize-control',
    inputClass: 'gs1selectize-input aoc-input',
    dropdownClass: 'gs1selectize-dropdown',
    dropdownContentClass: 'gs1selectize-dropdown-content'
  }, {
    labelField: 'wording',
    valueField: 'code',
    searchField: 'wording',
    maxItems: 1
  });
  public multiSelectOptions: any = Varietal;
  public aocSelectOptions: any = Aoc;
  public containerCodes = containerCodes;
  public selectedCategory: string;
  public gisCodes = GisCodes;
  @Output() public onModifyCategory = new EventEmitter<any>();
  @Input() public submitEvent: Observable<void>;
  public ingredientPlaceholder: string = '';
  public submitEventSubscription: Subscription;
  public showAddNutriment: boolean = true;
  public optionalNutrimentItems = optionalNutrimentItemsConst;
  public selectedNutriment: any;
  public optionalColumnHeight: number;
  public allergenIsSelected: boolean;
  public dailyValueIntakeReferenceEditable: boolean = false;
  public canSeeOptionalNutriment: boolean = false;

  private nbYears = new AgeOfSpiritModel().nbYears;

  constructor(public productsSvc: ProductsSvc,
    public featureToggleSvc: FeatureToggleSvc,
    public _router: Router,
    public route: ActivatedRoute,
    public barcodeGenerationSvc: BarcodeGenerationSvc,
    private notificationsSvc: NotificationsSvc,
    public companySvc: CompanySvc,
    public vintageSvc: VintageSvc,
    private productCategorySvc: ProductCategorySvc,
    private elRef: ElementRef,
    private headerSvc: DashboardHeaderSvc
  ) {

  }

  public ngOnDestroy() {
    this.submitEventSubscription?.unsubscribe();
  }

  public ngOnInit() {
    // subscribe to router event
    this.headerSvc.setHeaderData('Modification fiche produit', 'assets/icons/editCode.svg');

    this.submitEventSubscription = this.submitEvent.subscribe(() => this.submitForm());
    this.initProductForm();
    this.initClassification();
    this.resetWebLinkError();
    this.intIngredientStatement();
    if (this.unityForm.productForm.ingredientStatement.length !== 0) {
      this.ingredientPlaceholder = this.unityForm.productForm.ingredientStatement;
    }

    this.initNutriment();

    this.getAddedNutriments();
    this.allergenIsSelected = !_.isEmpty(this.unityForm.productForm.allergen);
  }

  public ngAfterViewInit() {
  }

  public modifyCategory() {
    this.onModifyCategory.emit(true);
  }

  public validateURL() {
    this.resetWebLinkError();
    this.unityForm.productForm.webLink = this.unityForm.productForm.webLink.toLowerCase();
    if (this.unityForm.productForm.webLink) {
      if (URL.isNotPrefixedWithProtocol(this.unityForm.productForm.webLink)) {
        this.unityForm.productForm.webLink = 'http://' + this.unityForm.productForm.webLink;
      }

      if (URL.isValidURL(this.unityForm.productForm.webLink)) {
        this.webLinkError = new FormInputError('valid', '');
      } else {
        this.webLinkError = new FormInputError('warning', DashboardConst.editForm.errors.webLinkInvalid);
      }

    }
  }

  public submitForm() {
    this.unityForm.productForm.productNames = _.filter(this.unityForm.productForm.productNames, (productName) => !_.isEmpty(productName.value) && !_.isEmpty(productName.lang));
    if (this.unityForm.productForm.adhereToRegulatoryAgreement) this.unityForm.productForm.preparationStateCode = 'UNPREPARED';
    if (this.hasSetNutrients() && !this.checkErrorsInQuantities()) {
      this.notificationsSvc.wrongNumberOfDecimalsDigits();
      return false;
    }
    if (this.hasSetNutrients() && !this.checkSugar()) {
      return false;
    }

    if (this.hasSetNutrients() && !this.isValidFatNutriments()) {
      this.notificationsSvc.wrongFoodNutriment();
      return false;
    }

    if (!this.unityForm.productForm.adhereToTheSectoralAgreement && !this.unityForm.productForm.adhereToRegulatoryAgreement && !this.allergenIsSelected) {
      this.unityForm.resetFormProductWitBaseAttribute();
      return this.unityForm.submitForm();
    }

    if (!this.unityForm.productForm.adhereToTheSectoralAgreement && (this.unityForm.productForm.adhereToRegulatoryAgreement || this.allergenIsSelected)) {
      this.unityForm.productForm.ingredientStatement = !_.isEmpty(this.unityForm.productForm.ingredientStatement) ? 'Ingrédients : ' + this.unityForm.productForm.ingredientStatement : this.unityForm.productForm.ingredientStatement;
      this.unityForm.resetFormProductWitRegulatoryAttribute();
      return this.unityForm.submitForm();
    }
    if (this.isWine) {
      this.unityForm.attributesToUpdate['wineColor'] = this.unityForm.productForm.wineColor;
    }

    if (this.isSpi) {
      this.unityForm.attributesToUpdate['spiritCodeGpc'] = this.unityForm.productForm.spiritCodeGpc;
    }

    if (this.unityForm.isProductAnyWine()) {
      this.unityForm.attributesToUpdate['sugarContent'] = this.unityForm.productForm.sugarContent;
    }
    this.unityForm.productForm.ingredientStatement = !_.isEmpty(this.unityForm.productForm.ingredientStatement) ? 'Ingrédients : ' + this.unityForm.productForm.ingredientStatement : this.unityForm.productForm.ingredientStatement;

    if (!this.hasSetNutrients()) {
      this.unityForm.productForm.preparationStateCode = null;
      this.unityForm.productForm.nutriment = null;
    }

    this.unityForm.submitForm();
  }


  public showIG() {
    const toReturn = this.isWine && this.unityForm.productForm.productCountry === '250';
    if (!toReturn) {
      this.unityForm.productForm.gis = '';
    }
    return this.isWine && this.unityForm.productForm.productCountry === '250';
  }

  public showAOC() {
    const toReturn = this.showIG() && ['AOC', 'AOP', 'IG', 'IGP'].indexOf(this.unityForm.productForm.gis) >= 0;
    if (!toReturn) {
      this.unityForm.productForm.aoc = '';
    }
    return toReturn;
  }

  public updateTextArea(editableContent: HTMLDivElement, ingredientStatement: HTMLTextAreaElement, e: Event) {
    if (editableContent.textContent.length > 10) {
      e.preventDefault();
    }
    this.unityForm.productForm.ingredientStatement = editableContent.textContent;
  }

  public onKeyDwn(editableContent: HTMLDivElement, e) {
    if (editableContent.textContent.length >= 5000 && !this.isAllowedKeyCode(e)) {
      e.preventDefault();
    }
  }


  public checkValue(event: { charCode: number; }) {
    return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 44;
  }

  public checkEnergeticValue(item: { measurementUnitCode: string; }, key: any) {
    const energyKeys = ['KJO', 'E14'];
    const indexKey = energyKeys.indexOf(item.measurementUnitCode);
    if (indexKey >= 0) {
      this.manageEnergeticValues(item, energyKeys[1 - indexKey], key);
    }
  }

  public manageEnergeticValues(item: { [x: string]: any; }, type: string, key: string | number) {
    if (item[key]) {
      let value = item[key];
      let otherValue: any = this.unityForm.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key];

      // value: , => .
      value = value.replace(',', '.');

      otherValue = '' + (
        type === 'E14' ?
          (parseFloat(value) * 0.239006) :
          (parseFloat(value) / 0.239006)
      ).toFixed(1);

      // otherValue: . => ,
      otherValue = otherValue.replace('.', ',');

      this.unityForm.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key] = otherValue;
    } else {
      this.unityForm.productForm.nutriment.items.find((f: any) => f.measurementUnitCode === type)[key] = '';
    }
  }

  public checkSugar() {
    const glucidesItem = this.unityForm.productForm.nutriment.items.find((f: any) => f.nutrientTypeCode === 'CHOAVL');
    const sugarItem = this.unityForm.productForm.nutriment.items.find((f: any) => f.nutrientTypeCode === 'SUGAR-');
    const keysToCheck = ['quantityContained'];         // , ['optionalQuantityContained', 'dailyValueIntakeReference']
    let checker = false;

    for (const key of keysToCheck) {
      // let key = keysToCheck[i];
      const glucidesValue = parseFloat(glucidesItem[key].replace(',', '.'));
      const sugarValue = parseFloat(sugarItem[key].replace(',', '.'));
      checker = checker || (sugarValue > glucidesValue);
      if (sugarValue > glucidesValue) {
        const sugarElement: HTMLElement = document.getElementById(`${key}_SUGAR-`);
        const glucidesElement: HTMLElement = document.getElementById(`${key}_CHOAVL`);
        sugarElement.className += ' inputError';
        glucidesElement.className += ' inputError';
      }
    }

    if (checker) {
      this.notificationsSvc.sugarSupToCarbohydrates();
      return false;
    }
    return true;
  }

  public addNutriment() {
    this.showAddNutriment = false;
  }

  public removeNutriment(nutriment: NutrimentItem, index: number) {
    const itemRemoved = _.filter(this.unityForm.requiredNutrimentItems, (nutrimentItem: any) => nutrimentItem.code === nutriment.nutrientTypeCode)[0] || {};
    // @ts-ignore
    this.unityForm.requiredNutrimentItems = _.filter(this.unityForm.requiredNutrimentItems, (nutrimentItem: NutrimentItem) => nutrimentItem.nutrientTypeCode !== nutriment.nutrientTypeCode);
    // @ts-ignore
    this.optionalNutrimentItems.push(itemRemoved);
    this.unityForm.productForm.nutriment.items = this.unityForm.productForm.nutriment.items.filter((item: NutrimentItem) => {
      return nutriment !== item;
    });
    this.selectedNutriment = null;
    this.optionalColumnHeight -= 60;
  }

  public selectNutriment(element: HTMLDivElement) {
    this.showAddNutriment = true;
    this.unityForm.requiredNutrimentItems.push(this.selectedNutriment);
    this.optionalNutrimentItems = _.filter(this.optionalNutrimentItems, (nutrimentItem) => nutrimentItem.code !== this.selectedNutriment.code);
    const nutriment = new NutrimentItem(this.selectedNutriment.code, 'APPROXIMATELY', null, null, this.selectedNutriment.measurementUnitCode, null, false);
    this.unityForm.productForm.nutriment.items.push(nutriment);
    this.optionalColumnHeight = element.offsetHeight + 60;
  }

  public hideOptionalNutriment() {
    this.canSeeOptionalNutriment = false;
  }

  public showOptionalNutriment() {
    this.canSeeOptionalNutriment = true;
  }


  public resetNutrimentClass(item: NutrimentItem) {
    const selector = item.nutrientTypeCode === 'ENER-' ? item.measurementUnitCode : item.nutrientTypeCode;
    const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#quantityContained_${selector}`);
    if (element) {
      element.className = element.className.replace('inputError', '');
    }
  }

  public hasSetNutrients(): boolean {
    let toReturn = false;
    this.unityForm.productForm.nutriment.items.forEach(item => {
      toReturn = toReturn || !_.isEmpty(item.quantityContained);
    });
    return toReturn;
  }

  public setAdhereToRegulatoryAgreement() {
    const ingredientStatementOrHasSetNutrients: boolean = !_.isEmpty(this.unityForm.productForm.ingredientStatement) || this.hasSetNutrients();
    this.unityForm.productForm.adhereToRegulatoryAgreement = ingredientStatementOrHasSetNutrients ;
  }

  public hasSetAllNutrients(): boolean {
    let toReturn = true;
    for (const item of this.unityForm.productForm.nutriment?.items) {
      if (_.isEmpty(item.quantityContained)) {
        toReturn = false;
      }
    }

    return toReturn;
  }

  public setAdhereToTheSectoralAgreement(isVintageChanged: boolean) {

    if (!_.isEmpty(this.unityForm.productForm.productCountry)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.spiritCodeGpc)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.spiritStyle)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.gis)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.aoc) && this.showAOC()) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.ageOfSpirit)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (typeof this.unityForm.productForm.vintage === 'number' && isVintageChanged) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.wineColor)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.alcoholDegree)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.container)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.sugarContent)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    if (!_.isEmpty(this.unityForm.productForm.varietals)) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }


    if (this.hasSetAtLeastOneCertifications()) {
      this.unityForm.productForm.adhereToTheSectoralAgreement = true;
      return;
    }

    this.unityForm.productForm.adhereToTheSectoralAgreement = false;
  }

  public hasSetAllSectoralAttributes(): boolean {

    if (!this.unityForm.productForm.adhereToTheSectoralAgreement) {
      return true;
    }

    if (_.isEmpty(this.unityForm.productForm.spiritCodeGpc) && this.isSpi) {
      return false;
    }

    if (_.isEmpty(this.unityForm.productForm.spiritStyle) && this.isSpi) {
      return false;
    }

    if (_.isEmpty(this.unityForm.productForm.aoc) && this.showAOC()) {
      return false;
    }

    if (_.isEmpty(this.unityForm.productForm.ageOfSpirit) && this.isSpi) {
      return false;
    }

    if (typeof this.unityForm.productForm.vintage !== 'number') {
      return false;
    }

    if (_.isEmpty(this.unityForm.productForm.wineColor) && this.isWine) {
      return false;
    }

    if (_.isEmpty(this.unityForm.productForm.alcoholDegree)) {
      return false;
    }

    if (_.isEmpty(this.unityForm.productForm.container)) {
      return false;
    }

    if (_.isEmpty(this.unityForm.productForm.sugarContent) && this.unityForm.isProductSparklingWine()) {
      return false;
    }

    if (!this.hasSetAllAllergens()) {
      return false;
    }

    return true;
  }

  public hasSetAtLeastOneCertifications(): boolean {
    if (_.isEmpty(this.unityForm.productForm.certifications)) {
      return false;
    }

    for (const key of Object.keys(this.unityForm.productForm.certifications)) {
      if (this.unityForm.productForm.certifications[key] === true) {
        return true;
      }

      if (!_.isEmpty(this.unityForm.productForm.certifications.AUTRE_CERTIF) || !_.isEmpty(this.unityForm.productForm.certifications.AUTRE_LABEL)) {
        return true;
      }
    }


    return false;
  }


  public allergenClicked($event) {
    const value = ($event.currentTarget as HTMLInputElement).value;
    const checked = ($event.currentTarget as HTMLInputElement).checked;
    const name = ($event.currentTarget as HTMLInputElement).name;
    this.affectAlergen(name, !checked ? null : value === 'true');
    this.allergenIsSelected = Object.keys(this.unityForm.productForm.allergen).length > 0;
  }

  public isAllergensRequired(allergenName: boolean): boolean {
    if (this.unityForm.productForm.adhereToRegulatoryAgreement || this.unityForm.productForm.adhereToTheSectoralAgreement || this.allergenIsSelected) {
      return _.isNil(allergenName);
    }
    return false;
  }

  public hasSetAllAllergens(): boolean {
    if (!this.isSpi) {
      return Object.keys(this.unityForm.productForm.allergen).length === 3;
    }

    return Object.keys(this.unityForm.productForm.allergen).length === 7;
  }

  public hasSetAllRegulatoryAttribute() {
    if (this.unityForm.productForm.adhereToRegulatoryAgreement) {
      return !_.isEmpty(this.unityForm.productForm.ingredientStatement) && this.hasSetAllNutrients() && this.hasSetAllAllergens();
    }
    return false;
  }

  private isAllowedKeyCode(event) {
    event = event || window.event;
    const charCode = (typeof event.which === 'undefined') ? event.keyCode : event.which;
    return charCode === 8 ||
      charCode === 38 ||
      charCode === 39 ||
      charCode === 37 ||
      charCode === 40 || (event.ctrlKey && charCode !== 86) ||
      event.metaKey;
  }

  private resetWebLinkError() {
    this.webLinkError = new FormInputError('', '');
  }

  // private makeAocElementRequired() {
  //     const divElement: HTMLElement = this.elRef.nativeElement.querySelector('.aoc-input');
  //     if (divElement) {
  //         const inputElement: HTMLInputElement = divElement.querySelector('input');
  //         inputElement.required = !this.unityForm.productForm.aoc || this.unityForm.productForm.aoc.length === 0;
  //     }
  // }

  private initProductForm() {
    this.unityForm.productForm.gtin = GtinUtils.constructGTINObject(this.product.gtin, this.product.prefix);
    // just to display target market label
    this.unityForm.productForm.targetMarketLabel = this.unityForm.getTargetMarketLabel(this.unityForm.productForm.targetMarket);

    this.unityForm.productForm.alcoholDegree = this.productsSvc.getFormatedAlcoholDegree(this.product.alcoholDegree);
    // Saving values that should not be changed
    this.unityForm.initialBrand = this.product.brand;
    this.unityForm.initialName = this.product.name;
    this.unityForm.initialProductForm = JSON.parse(JSON.stringify(this.unityForm.productForm));
    this.unityForm.productForm.container = (this.unityForm.productForm.container === 'BO_GLASS' || this.unityForm.productForm.container === 'BO_PLASTIC_OTHER') ? 'BO' : this.unityForm.productForm.container;
    this.unityForm.id = this.product.id;
    this.unityForm.getClassification();
    this.unityForm.initSugarContentList();
  }


  private initClassification() {
    const spiObject = _.find(this.productCategorySvc.categories, c => c.type === 'SPI') || {};
    const vinLiqueurObject = _.find(this.productCategorySvc.categories, c => c.type === 'VIN_DE_LIQUEUR') || {};
    const vinEffervescentObject = _.find(this.productCategorySvc.categories, c => c.type === 'VIN_EFFERVESCENT') || {};
    const vinTranquilleObject = _.find(this.productCategorySvc.categories, c => c.type === 'VIN_TRANQUILLE') || {};
    const objectList = [spiObject, vinLiqueurObject, vinEffervescentObject, vinTranquilleObject];
    this.unityForm.gpcCodes = [new Classification('0', 'others', '')];
    objectList.forEach((obj: any) => {
      this.unityForm.gpcCodes.push(new Classification(obj.codes[0], obj.category, obj.wording));
    });
  }

  private intIngredientStatement() {
    const firstPattern = 'Ingrédient :'.toUpperCase();
    const secondPattern = 'Ingrédients :'.toUpperCase();
    const firstIndex = this.unityForm.productForm.ingredientStatement.toUpperCase().indexOf(firstPattern);
    const secondIndex = this.unityForm.productForm.ingredientStatement.toUpperCase().indexOf(secondPattern);

    if (firstIndex !== -1) {
      this.unityForm.productForm.ingredientStatement = this.unityForm.productForm.ingredientStatement.slice(firstPattern.length, this.unityForm.productForm.ingredientStatement.length).trim();
    } else if (secondIndex !== -1) {
      this.unityForm.productForm.ingredientStatement = this.unityForm.productForm.ingredientStatement.slice(secondPattern.length, this.unityForm.productForm.ingredientStatement.length).trim();
    }
  }

  private initNutriment() {
    const { servingSize, measurementUnitCode, servingSizeDescription, items } = this.unityForm.productForm.nutriment;

    const builtItems = _.map(items, (item: NutrimentItem) => {
      const requiredItem: any = _.filter(this.unityForm.requiredNutrimentItems, (nutrimentItem: any) => nutrimentItem.code === item.nutrientTypeCode)[0] || {};
      const isRequired = requiredItem.isRequired ? requiredItem.isRequired : false;
      return new NutrimentItem(item.nutrientTypeCode, item.measurementPrecisionCode,
        this.productsSvc.replaceDotByCommaInString(item.quantityContained),
        this.productsSvc.replaceDotByCommaInString(item.optionalQuantityContained), item.measurementUnitCode,
        this.productsSvc.replaceDotByCommaInString(item.dailyValueIntakeReference), isRequired);
    });

    this.unityForm.productForm.nutriment = new Nutriment(servingSize, measurementUnitCode, servingSizeDescription, builtItems);
  }

  private getAddedNutriments() {
    if (this.unityForm.productForm.nutriment.items.length > 8) {
      const el = this.elRef.nativeElement.querySelector('.optionalColumn');
      if (el) {
        this.optionalColumnHeight = el.offsetHeight;
        for (let i = 0; (i + 8) < this.unityForm.productForm.nutriment.items.length; i++) {
          this.optionalColumnHeight += 60;
        }
      }

    }
  }

  private isValidFatNutriments(): boolean {

    const FASATNutriments = _.filter(this.unityForm.productForm.nutriment.items, (element: NutrimentItem) => element.nutrientTypeCode === 'FASAT');
    const FATNutriments = _.filter(this.unityForm.productForm.nutriment.items, (element: NutrimentItem) => element.nutrientTypeCode === 'FAT');
    const FASATNutriment: NutrimentItem = FASATNutriments[0];
    const FATNutriment: NutrimentItem = FATNutriments[0];
    const FASATQuantityContained = FASATNutriment.quantityContained ? FASATNutriment.quantityContained : '';
    const FATQuantityContained = FATNutriment.quantityContained ? FATNutriment.quantityContained : '';
    const toReturn = parseFloat(FASATQuantityContained.replace(/,/g, '.')) <= parseFloat(FATQuantityContained.replace(/,/g, '.'));
    const fasatElement: HTMLInputElement = this.elRef.nativeElement.querySelector('#quantityContained_FASAT');
    const fatElement: HTMLInputElement = this.elRef.nativeElement.querySelector('#quantityContained_FAT');
    // fasatElement.className = 'specificWidth';
    // fatElement.className = 'specificWidth';
    if (!toReturn) {
      fasatElement.className += ' inputError';
      fatElement.className += ' inputError';
      fasatElement.scrollIntoView({
        block: 'center'
      });
    }
    return toReturn;
  }

  private checkErrorsInQuantities(): boolean {
    let toReturn = true;
    this.unityForm.productForm.nutriment.items.forEach((i: any) => {
      toReturn = toReturn && (!i.hasErrorQuantityContained() && !i.hasErrorOptionalQuantityContained() && !i.hasErrorDailyValueIntakeReference());
      if (i.hasErrorQuantityContained()) {
        const selector = i.nutrientTypeCode === 'ENER-' ? i.measurementUnitCode : i.nutrientTypeCode;
        const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#quantityContained_${selector}`);
        if (!element.className.includes('inputError')) {
          element.className += ' inputError';
        }
      }
      if (i.hasErrorOptionalQuantityContained()) {
        const selector = i.nutrientTypeCode === 'ENER-' ? i.measurementUnitCode : i.nutrientTypeCode;
        const element: HTMLInputElement = this.elRef.nativeElement.querySelector(`#optionalQuantityContained_${selector}`);
        if (!element.className.includes('inputError')) {
          element.className += ' inputError';
        }
      }
    });
    return toReturn;
  }
  private affectAlergen(name, value) {

    switch (name) {

      case 'sulfit': !_.isNil(value) ? this.unityForm.productForm.allergen.AU = value : delete this.unityForm.productForm.allergen.AU;
        break;
      case 'oeuf': !_.isNil(value) ? this.unityForm.productForm.allergen.AE = value : delete this.unityForm.productForm.allergen.AE;
        break;
      case 'lait': !_.isNil(value) ? this.unityForm.productForm.allergen.AM = value : delete this.unityForm.productForm.allergen.AM;
        break;
      case 'gluten': !_.isNil(value) ? this.unityForm.productForm.allergen.AW = value : delete this.unityForm.productForm.allergen.AW;
        break;
      case 'crustace': !_.isNil(value) ? this.unityForm.productForm.allergen.UN = value : delete this.unityForm.productForm.allergen.UN;
        break;
      case 'arachide': !_.isNil(value) ? this.unityForm.productForm.allergen.AP = value : delete this.unityForm.productForm.allergen.AP;
        break;
      case 'fruitCoque': !_.isNil(value) ? this.unityForm.productForm.allergen.AN = value : delete this.unityForm.productForm.allergen.AN;
        break;
      default:
        break;
    }
  }

}
