import {forkJoin as observableForkJoin} from 'rxjs';
import {ProductClassificationIndex} from './../../models/productClassification.constants';
import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {DashboardConst} from '../../models/dashboard.constants';
import {Product} from '../../models/product.model';
import {ProductsSvc} from '../../services/products.service';
import {NotificationsSvc} from '../../../shared/services/index';
import {HttpErrorResponse} from '@angular/common/http';
import {FeatureToggleSvc} from '../../../shared/services/feature-toggle.service';

@Component({
  selector: `product-export-modal`,
  styleUrls: [`./product-export-modal.less`],
  template: `
    <div>
      <bs-modal class="productsExportModal" [keyboard]="false" [backdrop]="'static'" [size]="'lg'"
                #productsExportModal>
        <form (ngSubmit)="submitForm()" #formBarcode="ngForm" ngNativeValidate>
          <bs-modal-header [showDismiss]="true">
            <div class="productsExportModal-nav productsExportModal-nav-item active">
              <button type="button">{{constants.export.modal.title}}</button>
            </div>
          </bs-modal-header>
          <bs-modal-body>
            <div class="form-description" [hidden]="isOldGpcExport">{{constants.export.modal.subTitle}}</div>
            <div class="form-section row" [hidden]="selectedProducts.length === 0 || isOldGpcExport">
              <div class="form-section-category col-xs-12">
                <span>{{constants.export.modal.forms.content.title}}</span>
              </div>

              <div class="form-section-content dimension-content col-xs-12">
                <div class="col-xs-2">
                </div>
                <div class="col-xs-10">
                  <label class="radio-inline">
                    <input type="radio" name="type" id="selected" value="selected"
                           [checked]="exportSelectedProducts" (click)="exportSelection()">
                    <span class="radio-inline-text"> {{constants.export.modal.forms.content.radioButtons.selected}}
                      ({{selectedProducts.length}})</span>
                    <span class="check"></span>
                  </label>
                </div>
              </div>
              <div class="form-section-content dimension-content col-xs-12">
                <div class="col-xs-2"></div>
                <div class="col-xs-10">
                  <label class="radio-inline">
                    <input type="radio" name="type" id="all" value="all"
                           [checked]="!exportSelectedProducts" (click)="exportAll()">
                    <span class="radio-inline-text">{{constants.export.modal.forms.content.radioButtons.all}}</span>
                    <span class="check"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-section row">
              <div class="form-section-category col-xs-12">
                <span>{{constants.export.modal.forms.format.title}}</span>
                <div class="icon-help"
                     popoverTitle="{{constants.export.modal.help.title}}"
                     popover="{{constants.export.modal.help.content}}"
                     placement="bottom"
                     triggers="mouseenter:mouseleave">
                </div>
              </div>
              <div class="form-section-category information-section col-xs-11 warning-box"
                   *ngIf="shouldSendProductByMail(false)"
                   id="warningBox">
                <div class="title">
                  <img src="assets/icons/info-circle-blue.svg" alt="titre">
                  <span>Important</span>
                </div>
                <div>
                  <div class="body">
                    <span>{{constants.export.modal.forms.content.information}}</span><br/><br/>
                  </div>
                </div>

              </div>
              <div class="form-section-content dimension-content col-xs-12"
                   *ngIf="this.canSeeExportExcel()">
                <div class="col-xs-2"></div>
                <div class="col-xs-10">
                  <label class="radio-inline">
                    <input type="radio" name="format" id="excel" value="excel"
                           [checked]="excel" (click)="formatExcel()">
                    <span class="radio-inline-text"
                          *ngIf="!shouldSendProductByMail(true)"> {{constants.export.modal.forms.format.radioButtons.excel}}</span>
                    <span class="radio-inline-text"
                          *ngIf="shouldSendProductByMail(true)"> {{constants.export.modal.forms.format.radioButtons.excelWithoutRecommended}}</span>
                    <span class="check"></span>
                  </label>
                </div>
              </div>
              <div class="form-section-content dimension-content col-xs-12">
                <div class="col-xs-2"></div>
                <div class="col-xs-10">
                  <label class="radio-inline">
                    <input type="radio" name="format" id="formatCsvForExcel" value="formatCsvForExcel"
                           [checked]="formatCsvExcel" (click)="formatCsvForExcel()">
                    <span
                      class="radio-inline-text"> {{constants.export.modal.forms.format.radioButtons.csvFormatExcel}}</span>
                    <span class="check"></span>
                  </label>
                </div>
              </div>
              <div class="form-section-content dimension-content col-xs-12">
                <div class="col-xs-2"></div>
                <div class="col-xs-10">
                  <label class="radio-inline">
                    <input type="radio" name="format" id="other" value="other"
                           [checked]="formatOther" (click)="formatForOthers()">
                    <span class="radio-inline-text"> {{constants.export.modal.forms.format.radioButtons.others}}</span>
                    <span class="check"></span>
                  </label>
                </div>
              </div>
            </div>
          </bs-modal-body>
          <bs-modal-footer [showDefaultButtons]="false">
            <div class="productsExportModal-loader">
              <loader [color]="'blue'" [hidden]="!exportingProducts" [size]="'25px'"></loader>
            </div>
            <button type="submit" class="productsExportModal-submitButton" [disabled]="exportingProducts">
              {{constants.export.modal.buttons.download}}
            </button>
          </bs-modal-footer>
        </form>
      </bs-modal>
    </div>
  `
})
export class ProductExportModal implements OnInit {


  @ViewChild(BsModalComponent, {static: true})
  public modal: BsModalComponent;

  public constants = DashboardConst;
  public exportSelectedProducts: boolean;
  public selectedProducts: Product[] = [];
  public exportingProducts: boolean = false;
  public excel: boolean = true;
  public formatOther: boolean = false;
  public formatCsvExcel: boolean = false;
  public productCategories = ProductClassificationIndex;
  @Output() public onCloseModal = new EventEmitter<any>();
  public isOldGpcExport: boolean = false;

  constructor(public productsSvc: ProductsSvc, private notificationsSvc: NotificationsSvc, private featureToggleSvc: FeatureToggleSvc) {
  }

  public ngOnInit(): void {
    this.modal.onOpen.subscribe(() => {
      if (this.canSeeExportExcel()) {
        this.excel = true;
        this.formatOther = false;
        this.formatCsvExcel = false;
        return;
      }
      this.excel = false;
      this.formatOther = false;
      this.formatCsvExcel = true;
    });
  }

  public exportAll() {
    this.exportSelectedProducts = false;
  }

  public exportSelection() {
    this.exportSelectedProducts = true;
  }

  public formatForOthers() {
    this.formatOther = true;
    this.formatCsvExcel = false;
    this.excel = false;
  }

  public formatCsvForExcel() {
    this.formatCsvExcel = true;
    this.formatOther = false;
    this.excel = false;
  }

  public formatExcel() {
    this.excel = true;
    this.formatCsvExcel = false;
    this.formatOther = false;
  }

  public show(selectedProducts: Product[], isOldGpcExport = false) {
    this.isOldGpcExport = isOldGpcExport;
    this.exportSelectedProducts = selectedProducts.length > 0;
    this.selectedProducts = selectedProducts;
    this.modal.open();
  }

  public submitForm() {
    this.exportingProducts = true;
    const selection = (this.exportSelectedProducts) ? this.selectedProducts : [];
    const groupSelection = this.getProductByClassifications(selection);
    this._exportProducts(groupSelection);
  }

  public canSeeExportExcel() {
    return this.featureToggleSvc.canExport();
  }

  public shouldSendProductByMail(recommendedMessage = false) {
    if (!this.excel && !recommendedMessage) {
      return false;
    }
    if (this.exportSelectedProducts && this.selectedProducts.length >= 10000) {
      return true;
    }

    if (this.isOldGpcExport && this.productsSvc.totalProductsWithOldGpcCode > 10000) {
      return true;
    } else if (this.isOldGpcExport && this.productsSvc.totalProductsWithOldGpcCode <= 10000) {
      return false;
    }


    return !this.exportSelectedProducts && this.productsSvc.totalAllProducts >= 10000;


  }

  private _exportProducts(groupSelection) {
    if (this.shouldSendProductByMail(false)) {
      this.productsSvc.sendProductsByMail(this.isOldGpcExport).then((result: any) => {
        const sendingMail = result.sendingMail;
        if (sendingMail) {
          this.exportingProducts = false;
          this.onCloseModal.emit();
          this.modal.close();
          this.notificationsSvc.exportInProgress();
          return;
        }
        this._executeExportFunctions(this._prepareExportProductFunctions(groupSelection));
      });
      return;
    }
    const exportProducts = this._prepareExportProductFunctions(groupSelection);
    this._executeExportFunctions(exportProducts);
  }

  private _executeExportFunctions(exportProducts) {
    observableForkJoin(exportProducts)
      .subscribe(
        () => {
          this.exportingProducts = false;
          this.onCloseModal.emit();
          this.modal.close();
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              this.notificationsSvc.serverError();
              this.exportingProducts = false;
              this.modal.close();
            }
          }
        }
      );
  }

  private _prepareExportProductFunctions(groupSelection) {
    const exportProducts = [];
    if (this.isOldGpcExport) {
      return [this.productsSvc.exportProducts([], this.formatCsvExcel, this.excel, [], this.isOldGpcExport)];
    }
    groupSelection.forEach((products, classification) => {
      const classifications = [classification];
      let isBundle = false;
      let isNotNew = false;

      switch (classification) {
        case this.productCategories.BUNDLE: {
          isBundle = true;
          classifications.push(this.productCategories.GENERIC_PRODUCT_GPC_CODE);
          break;
        }
        case this.productCategories.NOT_NEW: {
          isNotNew = true;
          classifications.push(this.productCategories.GENERIC_PRODUCT_GPC_CODE); // like bundle as if it is a Generic one
          break;
        }
        case this.productCategories.STILL_WINE_GPC_CODE: {
          classifications.push(this.productCategories.SPARKLING_WINE_GPC_CODE);
          classifications.push(this.productCategories.SPARKLING_WINE_GPC_CODE_NOTALCOHOLIC);
          classifications.push(this.productCategories.LIQUEUR_WINE_GPC_CODE);
          classifications.push(this.productCategories.PREMIXED_ALCOHOLIC_GPC_CODE);
          classifications.push(this.productCategories.STILL_WINE_GPC_CODE_NOTALCOHOLIC);
          break;
        }
        default:
          break;
      }

      exportProducts.push(this.productsSvc.exportProducts(products, this.formatCsvExcel, this.excel, classifications, false, isBundle, isNotNew));
    });

    return exportProducts;
  }

  private getProductByClassifications(products?: Product[]): Map<string, Product[]> {

    const groupedProducts = new Map<string, Product[]>();

    if (products.length === 0) {
      groupedProducts.set(this.productCategories.SPIRIT_GPC_CODE, new Array<Product>());
      groupedProducts.set(this.productCategories.STILL_WINE_GPC_CODE, new Array<Product>());
      groupedProducts.set(this.productCategories.GENERIC_PRODUCT_GPC_CODE, new Array<Product>());
      groupedProducts.set(this.productCategories.BUNDLE, new Array<Product>());
      groupedProducts.set(this.productCategories.NOT_NEW, new Array<Product>());
      return groupedProducts;
    }

    for (const product of products) {
      let currentClassification: string;

      if (product.productType === 'BUNDLE') {
        currentClassification = this.productCategories.BUNDLE;
      } else if (product.productResellerType === 'NOT_NEW') {
        currentClassification = this.productCategories.NOT_NEW;
      } else {
        switch (product.codeGpc) {
          case this.productCategories.SPARKLING_WINE_GPC_CODE:
          case this.productCategories.STILL_WINE_GPC_CODE:
          case this.productCategories.PREMIXED_ALCOHOLIC_GPC_CODE:
          case this.productCategories.LIQUEUR_WINE_GPC_CODE:
          case this.productCategories.STILL_WINE_GPC_CODE_NOTALCOHOLIC:
          case this.productCategories.SPARKLING_WINE_GPC_CODE_NOTALCOHOLIC:
            currentClassification = this.productCategories.STILL_WINE_GPC_CODE;
            break;
          case this.productCategories.SPIRIT_GPC_CODE:
            currentClassification = this.productCategories.SPIRIT_GPC_CODE;
            break;
          default:
            currentClassification = this.productCategories.GENERIC_PRODUCT_GPC_CODE;
            break;
        }
      }


      const currentProducts = groupedProducts.get(currentClassification) || new Array<Product>();

      currentProducts.push(product);
      groupedProducts.set(currentClassification, currentProducts);
    }

    return groupedProducts;

  }
}
