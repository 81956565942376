<div class="Unity Consumer defineScrollbar">
  <form (ngSubmit)="next(false, formProduct)" #formProduct="ngForm" ngNativeValidate>
    <div class="Unity-header">
      <div class="modal-title"></div>
    </div>
    <div class="Unity-body">
      <!-- FORM -->
      <div class="Unity-form container-fluid">
        <!-- Introduction section -->
        <div class="Unity-form-section row">
          <div class="Unity-form-section-name section-title col-md-12 col-xs-12 regulatoryAgreementTitle">Informations
            réglementaires du produit
          </div>
          <gs1-link-card class="card">
            <p class="title">Vous souhaitez être en conformité avec la réglementation européenne sur les vins dès à présent ?</p>
            <p>GS1 France vous propose de renseigner la liste des ingrédients et les valeurs nutritionnelles
              pour générer le QR Code augmenté GS1 à imprimer sur les étiquettes.
            </p>
          </gs1-link-card>
        </div>
        <!-- ingredientStatement -->
        <div class="Unity-form-section row">
          <div class="Unity-form-section-name col-md-2 col-xs-4">
            Composition
          </div>
          <div class="Unity-form-section-content col-md-10 col-xs-4 ingredientsArea">
            <div class="Unity-form-subSection row topLeftContainer">
              <div class="Unity-form-input col-md-6 col-xs-6">
                <label for="ingredientStatement">
                  Liste des ingrédients
                </label>
                <div class="icon-help"
                     popoverTitle="{{constants.inputsInfo.ingredientStatementVcs.title}}"
                     popover="{{constants.inputsInfo.ingredientStatementVcs.content}}"
                     placement="right"
                     triggers="mouseenter:mouseleave">
                </div>
                <div class="ingredientsContainer">
                  <div class="textareaHeader" (click)="editableContent.focus();">Ingrédients:</div>
                  <div class="textAreaContainer"
                       [class.ng-invalid]="ingredientStatementElement.validity.valid === false"
                       (click)="editableContent.focus();">
                    <div class="textAreaVisible defineScrollbar" contenteditable="true"
                         (keydown)="onKeyDwn(editableContent, $event)" #editableContent
                         (keyup)="updateTextArea(editableContent); setAdhereToRegulatoryAgreement()"
                         data-max-length="5000">{{ ingredientPlaceholder }}
                    </div>
                    <textarea id="ingredientStatement" class="mt-2 ingredient"
                              maxlength="5000"
                              [maxLength]="'5000'"
                              #ingredientStatementElement
                              [(ngModel)]="unityForm.productForm.ingredientStatement"
                              [required]="unityForm.productForm.adhereToRegulatoryAgreement"
                              name="ingredientStatement" #ingredientStatement>
                                                </textarea>
                    <div class="textAreaFooter" *ngIf="unityForm.productForm.ingredientStatement.length > 0"
                         (click)="editableContent.focus();">
                      <gs1-alert class="ingredientTextAreaFooterAlert" [title]="'Important !'" [showIcon]="false"
                                 [content]="'Renseignez en majuscules les substances ou produits allergènes mis en ' +
                                  'évidence dans la liste des ingrédients présente sur l’emballage de la denrée alimentaire. La mise en majuscule ne doit être utilisée que pour les substances ou produits allergènes.'"
                                 [type]="'warning'"></gs1-alert>
                    </div>
                  </div>
                  <div class="Unity-form-infoText">{{ 5000 - unityForm.productForm.ingredientStatement.length }}
                    /5000
                    {{ unityForm.constants.createForm.helpers.inputTextInfoLength }}
                  </div>
                </div>
              </div>
              <div class="Unity-form-input col-md-6 col-xs-6 alert"
                   *ngIf="ingredientStatementElement.validity.valid === false ">
                <gs1-alert [title]="'Veuillez remplir les champs pour continuer'" [showIcon]="false"
                           [content]="'Si vous souhaitez générer le QR Code augmenté GS1 de votre produit, tous les champs « Composition », « Allergènes » et « Valeurs nutritionnelles » doivent être renseignés.'"
                           [type]="'danger'"></gs1-alert>
              </div>
            </div>
          </div>
        </div>

        <!--Allergen section-->
        <div class="Unity-form-section row">
          <div class="Unity-form-section-name col-md-2 col-xs-4">Allergènes</div>
          <div class="Unity-form-section-content alert-width col-md-12">
            <div class="Unity-form-subSection row">
              <!--subsection VCS-->
              <div class="allergens-base">
                <!--sulfit-->
                <div class="Unity-form-input col-md-12 col-xs-12">
                  <label>Contient des sulfites</label>
                  <div class="icon-help"
                       popoverTitle="{{unityForm.constants.inputsInfo.allergen.AU.title}}"
                       popover="{{unityForm.constants.inputsInfo.allergen.AU.content}}"
                       placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <div class="checkbox">
                    <input id="sulfites-oui" type="checkbox" name="sulfit" [value]=true
                           [checked]="unityForm.productForm.allergen.AU==true"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AU)"
                           (click)="allergenClicked($event)">
                    <label for="sulfites-oui">Oui</label>
                  </div>
                  <div class="checkbox">
                    <input id="sulfites-non" type="checkbox" name="sulfit"
                           [checked]="unityForm.productForm.allergen.AU==false"
                           [value]=false
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AU)"
                           (click)="allergenClicked($event)">
                    <label for="sulfites-non">Non</label>
                  </div>
                </div>
                <!--oeuf-->
                <div class="Unity-form-input col-md-12 col-xs-12">
                  <label>Contient de l'œuf ou dérivé</label>
                  <div class="icon-help"
                       popoverTitle="{{unityForm.constants.inputsInfo.allergen.AE.title}}"
                       popover="{{unityForm.constants.inputsInfo.allergen.AE.content}}"
                       placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <div class="checkbox">
                    <input id="oeuf-oui" type="checkbox" name="oeuf"
                           [value]=true 
                           [checked]="unityForm.productForm.allergen.AE==true"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AE)"
                           (click)="allergenClicked($event)">
                    <label for="oeuf-oui">Oui</label>
                  </div>
                  <div class="checkbox">
                    <input id="oeuf-non" type="checkbox" name="oeuf"
                           [value]=false [checked]="unityForm.productForm.allergen.AE==false"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AE)"
                           (click)="allergenClicked($event)">
                    <label for="oeuf-non">Non</label>
                  </div>
                </div>
                <!--lait-->
                <div class="Unity-form-input col-md-12 col-xs-12">
                  <label>Contient du lait ou dérivé</label>
                  <div class="icon-help"
                       popoverTitle="{{unityForm.constants.inputsInfo.allergen.AM.title}}"
                       popover="{{unityForm.constants.inputsInfo.allergen.AM.content}}"
                       placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <div class="checkbox">
                    <input id="lait-oui" type="checkbox" name="lait"
                           [value]=true [checked]="unityForm.productForm.allergen.AM==true"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AM)"
                           (click)="allergenClicked($event)">
                    <label for="lait-oui">Oui</label>
                  </div>
                  <div class="checkbox">
                    <input id="lait-non" type="checkbox" name="lait"
                           [value]=false [checked]="unityForm.productForm.allergen.AM==false"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AM)"
                           (click)="allergenClicked($event)">
                    <label for="lait-non">Non</label>
                  </div>
                </div>
              </div>
              <!--subsection SPI-->
              <div *ngIf="isSpi">
                <!--gluten-->
                <div class="Unity-form-input col-md-12 col-xs-12">
                  <label>Contient Céréale contenant du gluten</label>
                  <div class="icon-help"
                       popoverTitle="{{unityForm.constants.inputsInfo.allergen.AW.title}}"
                       popover="{{unityForm.constants.inputsInfo.allergen.AW.content}}"
                       placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <div class="checkbox">
                    <input id="gluten-oui" type="checkbox" name="gluten" [value]=true
                           [checked]="unityForm.productForm.allergen.AW==true"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AW)"
                           (click)="allergenClicked($event)">
                    <label for="gluten-oui">Oui</label>
                  </div>
                  <div class="checkbox">
                    <input id="gluten-non" type="checkbox" name="gluten"
                           [checked]="unityForm.productForm.allergen.AW==false"
                           [value]=false
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AW)"
                           (click)="allergenClicked($event)">
                    <label for="gluten-non">Non</label>
                  </div>
                </div>
                <!--crustacé-->
                <div class="Unity-form-input col-md-12 col-xs-12">
                  <label>Contient des crustacés</label>
                  <div class="icon-help"
                       popoverTitle="{{unityForm.constants.inputsInfo.allergen.UN.title}}"
                       popover="{{unityForm.constants.inputsInfo.allergen.UN.content}}"
                       placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <div class="checkbox">
                    <input id="crustaces-oui" type="checkbox" name="crustace"
                           [value]=true [checked]="unityForm.productForm.allergen.UN==true"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.UN)"
                           (click)="allergenClicked($event)">
                    <label for="crustaces-oui">Oui</label>
                  </div>
                  <div class="checkbox">
                    <input id="crustaces-non" type="checkbox" name="crustace"
                           [value]=false [checked]="unityForm.productForm.allergen.UN==false"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.UN)"
                           (click)="allergenClicked($event)">
                    <label for="crustaces-non">Non</label>
                  </div>
                </div>
                <!--arachide-->
                <div class="Unity-form-input col-md-12 col-xs-12">
                  <label>Contient des arachides et produits à base d'arachides</label>
                  <div class="icon-help"
                       popoverTitle="{{unityForm.constants.inputsInfo.allergen.AP.title}}"
                       popover="{{unityForm.constants.inputsInfo.allergen.AP.content}}"
                       placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <div class="checkbox">
                    <input id="arachides-oui" type="checkbox" name="arachide"
                           [value]=true [checked]="unityForm.productForm.allergen.AP==true"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AP)"
                           (click)="allergenClicked($event)">
                    <label for="arachides-oui">Oui</label>
                  </div>
                  <div class="checkbox">
                    <input id="arachides-non" type="checkbox" name="arachide"
                           [value]=false [checked]="unityForm.productForm.allergen.AP==false"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AP)"
                           (click)="allergenClicked($event)">
                    <label for="arachides-non">Non</label>
                  </div>
                </div>
                <!--fruit à coque-->
                <div class="Unity-form-input col-md-12 col-xs-12">
                  <label>Contient des fruits à coque</label>
                  <div class="icon-help"
                       popoverTitle="{{unityForm.constants.inputsInfo.allergen.AN.title}}"
                       popover="{{unityForm.constants.inputsInfo.allergen.AN.content}}"
                       placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <div class="checkbox">
                    <input id="coque-oui" type="checkbox" name="fruitCoque"
                           [value]=true [checked]="unityForm.productForm.allergen.AN==true"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AN)"
                           (click)="allergenClicked($event)">
                    <label for="coque-oui">Oui</label>
                  </div>
                  <div class="checkbox">
                    <input id="coque-non" type="checkbox" name="fruitCoque"
                           [value]=false [checked]="unityForm.productForm.allergen.AN==false"
                           [required]="allergensAreRequired(unityForm.productForm.allergen.AN)"
                           (click)="allergenClicked($event)">
                    <label for="coque-non">Non</label>
                  </div>
                </div>
              </div>
              <div class="shift-left">
                <gs1-alert [title]="'Veuillez remplir les champs pour continuer'" [showIcon]="false"
                           *ngIf="(unityForm.productForm.adhereToTheSectoralAgreement || unityForm.productForm.adhereToRegulatoryAgreement || allergenIsSelected)  && !hasSetAllAllergens()"
                           [content]="'Si vous souhaitez générer le QR Code augmenté GS1 de votre produit, tous les champs « Composition », « Allergènes » et « Valeurs nutritionnelles » doivent être renseignés.'"
                           [type]="'danger'"></gs1-alert>
              </div>
            </div>
          </div>
        </div>

        <!--Nutriments-->
        <div class="Unity-form-section row">
          <div class="Unity-form-section-name col-md-2 col-xs-4 nutriValuesTitle">
            Valeurs nutritionnelles
          </div>
          <div class="Unity-form-section-content vcsNutriValuesContainer">
            <div class="Unity-form-subSection row nutri-values">
              <div class="Unity-form-input col-xs-12 mt-3">
                <label class="padding-left">Valeurs nutritionnelles</label>
                <div class="icon-help"
                     popoverTitle="{{constants.inputsInfo.nutriment.title}}"
                     popover="{{constants.inputsInfo.nutriment.content}}"
                     placement="right"
                     triggers="mouseenter:mouseleave">
                </div>
                <div class="row">
                  <div class="vcsLabelContainer col-xs-2">
                    <div class="col-xs-12 itemSpace"
                         *ngFor="let item of unityForm.productForm.nutriment.items; index as i">
                      <div class="row">
                        <div class="col-xs-2 valueLabel">
                          {{
                            findNutrimentWording(item.nutrientTypeCode,
                              item.measurementUnitCode)
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vcsValuesContainer col-xs-2">
                    <div class="col-xs-12 text-center portion">
                      pour {{ findServingSizeWording(unityForm.productForm.nutriment.measurementUnitCode) }}
                    </div>
                    <div class="col-xs-12 valuesColumn "
                         *ngFor="let item of unityForm.productForm.nutriment.items; index as i">
                      <div class="valuesCell">
                        <input class="specificWidth item_quantityContained"
                               [required]="hasSetNutrients() || unityForm.productForm.adhereToRegulatoryAgreement"
                               type="text"
                               autocomplete="false"
                               (keypress)="checkValue($event)"
                               (input)="checkEnergeticValue(item, 'quantityContained'); resetNutrimentClass(item, 'quantityContained');  setAdhereToRegulatoryAgreement()"
                               [(ngModel)]="item.quantityContained"
                               maxlength="10"
                               id="quantityContained_{{item.nutrientTypeCode === 'ENER-' ? item.measurementUnitCode : item.nutrientTypeCode}}"
                               name="quantityContained_ {{i}}" #name="ngModel"
                               [ngClass]="{'error': item.hasErrorQuantityContained()}">
                      </div>
                      <div class="valuesUnit">
                        {{ findMeasurementUnitWording(item.measurementUnitCode) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 removeNutrimentContainer">
                    <div class="col-md-12 col-xs-12"
                         *ngIf=" !hasSetAllNutrients() && unityForm.productForm.adhereToRegulatoryAgreement ">
                      <gs1-alert [title]="'Veuillez remplir les champs pour continuer'" [showIcon]="false"
                                 [content]="'Si vous souhaitez générer le QR Code augmenté GS1 de votre produit, tous les champs « Composition », « Allergènes » et « Valeurs nutritionnelles » doivent être renseignés.'"
                                 [type]="'danger'"></gs1-alert>
                    </div>
                    <div class="portionMargin"></div>
                    <div *ngFor="let item of unityForm.productForm.nutriment.items; index as i"
                         [className]="item.isRequired ? 'blankSpace removeNutriment' : 'removeNutriment'">
                                                    <span class="col-xs-12 removeNutrimentBtn" *ngIf="!item.isRequired"
                                                          (click)="removeNutriment(item, i)">x</span>
                    </div>
                  </div>
                </div>
                <div class="Unity-form-input col-xs-12 mt-3">

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Nutritional Values-->


        <!-- /Nutritional Values-->

        <!-- Introduction sectorial section -->
        <div class="Unity-form-section  sectorial-section row">
          <div class="Unity-form-section-name section-title col-md-2 col-xs-4">Informations sectorielles du produit
          </div>
        </div>
        <!-- spirits type section -->
        <div class="Unity-form-section row" *ngIf="isSpi">
          <div class="Unity-form-section-name col-md-2 col-xs-4">Spiritueux</div>
          <div class="Unity-form-section-content col-md-10 col-xs-8">
            <div class="Unity-form-subSection row">
              <div class="Unity-form-input col-md-12 col-xs-12">
                <div class="Unity-form-input">
                  <label for="typeOfSpirits">Type de spiritueux <span
                    class="Unity-form-input--required">(obligatoire)</span></label>
                  <div class="row">
                    <div class="Unity-form-input-gtin--prefix col-xs-3 text-center ">
                      <select name="typeOfSpirits" id="typeOfSpirits"
                              [required]="unityForm.productForm.adhereToTheSectoralAgreement"
                              [(ngModel)]="unityForm.productForm.spiritCodeGpc"
                              (change)="setAdhereToTheSectoralAgreement(false)">
                        <option value=""></option>
                        <option *ngFor="let spirit of unityForm.spirits"
                                [ngValue]="spirit.code">
                          {{ spirit.wording }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="Unity-form-input mt-3 col-md-12 col-xs-122">
                <div class="Unity-form-input">
                  <label>Style de spiritueux </label>
                  <div class="row">
                    <div class="Unity-form-input-gtin--prefix col-xs-3 text-center ">
                      <select name="spiritStyle" id="spiritStyle"
                              [(ngModel)]="unityForm.productForm.spiritStyle"
                              (change)="setAdhereToTheSectoralAgreement(false)">
                        <option value=""></option>
                        <option *ngFor="let spiritStyle of unityForm.spiritStyles"
                                [ngValue]="spiritStyle.code">
                          {{ spiritStyle.wording }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Origin Country -->
        <div class="Unity-form-section row">
          <div class="Unity-form-section-name col-md-2 col-xs-4">Origine</div>
          <div class="Unity-form-section-content col-md-10 col-xs-8">
            <div class="Unity-form-subSection row">
              <div class="Unity-form-input col-md-6 col-xs-12">
                <label for="country">Pays d'origine <span
                  class="Unity-form-input--required">(obligatoire)</span></label>
                <div class="icon-help"
                     popoverTitle="{{unityForm.constants.inputsInfo.country.title}}"
                     popover="{{unityForm.constants.inputsInfo.country.content}}"
                     placement="right"
                     triggers="mouseenter:mouseleave">
                </div>
                <div class="row">
                  <div class="Unity-form-input-gtin--prefix col-xs-6 text-center ">
                    <select name="country" id="country" [required]="unityForm.productForm.adhereToTheSectoralAgreement"
                            [(ngModel)]="unityForm.productForm.productCountry"
                            (change)="unityForm.productForm.adhereToTheSectoralAgreement = true">
                      <option *ngFor="let country of unityForm.countries"
                              [ngValue]="country.code">{{ country.wording }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <!--wine color-->
              <div class="Unity-form-input mt-3 col-md-12 col-xs-12" *ngIf="showIG()">
                <label>Signe d'indication géographique</label>
                <div class="row">
                  <div class="Unity-form-input-gtin--prefix col-md-4 col-xs-6 text-center ">
                    <select name="gis" id="gis"
                            [(ngModel)]="unityForm.productForm.gis" (change)="setAdhereToTheSectoralAgreement(false)">
                      <option value=""></option>
                      <option *ngFor="let gis of unityForm.gisCodes"
                              [ngValue]="gis.code">{{ gis.wording }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- AOC -->
              <div class="Unity-form-input  mt-3 col-md-6 col-xs-12"
                   *ngIf="showAOC()">
                <label>Indication géographique</label>
                <div class="icon-help"
                     popoverTitle="{{unityForm.constants.inputsInfo.aoc.title}}"
                     popover="{{unityForm.constants.inputsInfo.aoc.content}}"
                     placement="right"
                     triggers="mouseenter:mouseleave">
                </div>
                <div class="row">
                  <div class="Unity-form-input-gtin--prefix col-md-12 col-xs-12 text-center ">
                    <ng-selectize name="aoc" [config]="aocSelectConfig" style="text-transform: uppercase;"
                                  [options]="aocSelectOptions"
                                  [(ngModel)]="unityForm.productForm.aoc"
                                  (ngModelChange)="setAdhereToTheSectoralAgreement(false)"
                                  ngDefaultControl></ng-selectize>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Specific section -->
        <div class="Unity-form-section row">
          <div class="Unity-form-section-name col-md-2 col-xs-4">Sp&eacute;cificit&eacute;s</div>
          <div class="Unity-form-section-content col-md-10 col-xs-8">
            <div class="Unity-form-subSection row">
              <!--Main-->
              <!--age of the spirit-->
              <div class="Unity-form-input col-md-12 col-xs-12"
                   *ngIf="isSpi">
                <label>&Acirc;ge du spiritueux</label>
                <div class="icon-help"
                     popoverTitle="{{unityForm.constants.inputsInfo.ageOfSpirit.title}}"
                     popover="{{unityForm.constants.inputsInfo.ageOfSpirit.content}}"
                     placement="right"
                     triggers="mouseenter:mouseleave">
                </div>
                <div class="Unity-form-input-vcs row">
                  <div class="col-md-3 col-xs-5">
                    <select name="ageOfSpirit" id="ageOfSpirit"
                            [(ngModel)]="unityForm.productForm.ageOfSpirit"
                            (change)="setAdhereToTheSectoralAgreement(false)">
                      <option value=""></option>
                      <option *ngFor="let nbYear of nbYears"
                              [ngValue]="nbYear">{{ nbYear }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2 col-xs-2">Ans</div>
                </div>
              </div>
              <!--vintage section-->
              <div [class.mt-3]="isSpi"
                   class="Unity-form-input col-md-12 col-xs-12">
                <label for="vintage">Millésime <span
                  class="Unity-form-input--required">(obligatoire)</span></label>
                <div class="row">
                  <div class="Unity-form-input-gtin--prefix col-md-3 col-xs-6 text-center ">
                    <select name="vintage" id="vintage"
                            [required]="unityForm.productForm.adhereToTheSectoralAgreement"
                            [(ngModel)]="unityForm.productForm.vintage"
                            (change)="setAdhereToTheSectoralAgreement(true)">
                      <option value=""></option>
                      <option *ngFor="let vintage of vintageSvc.vintages"
                              [ngValue]="vintage.code">{{ vintage.wording }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!--wine color-->
              <div class="Unity-form-input mt-3 col-md-12 col-xs-12"
                   *ngIf="isWine">
                <label>Couleur du vin
                  <span class="Unity-form-input--required">(obligatoire)</span></label>
                <div class="row">
                  <div class="Unity-form-input-gtin--prefix col-md-3 col-xs-6 text-center ">
                    <select name="wineColor" id="wineColor"
                            [required]="isWine && unityForm.productForm.adhereToTheSectoralAgreement"
                            [(ngModel)]="unityForm.productForm.wineColor"
                            (change)="setAdhereToTheSectoralAgreement(false)">
                      <option value=""></option>
                      <option *ngFor="let wineColor of unityForm.wineColorCodes"
                              [ngValue]="wineColor.code">{{ wineColor.wording }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="Unity-form-input mt-3 col-md-12 col-xs-12"
                   [ngClass]="'formError-' + unityForm.productForm.alcoholDegreeError.status">
                <label for="specific">Quantit&eacute; d'alcool <span
                  class="Unity-form-input--required">(obligatoire)</span></label>
                <div class="icon-help"
                     popoverTitle="{{unityForm.constants.inputsInfo.alcoholDegree.title}}"
                     popover="{{unityForm.constants.inputsInfo.alcoholDegree.content}}"
                     placement="right"
                     triggers="mouseenter:mouseleave">
                </div>
                <div class="Unity-form-input-vcs row">
                  <div class="col-md-3 col-xs-5">
                    <input id="specific" type="text" maxlength="4"
                           [(ngModel)]="unityForm.productForm.alcoholDegree"
                           (ngModelChange)="unityForm.onAlcoholDegreeChange($event); setAdhereToTheSectoralAgreement(false)"
                           pattern="{{unityForm.DECIMAL_PATTERN}}"
                           placeholder="{{unityForm.constants.createForm.placeholders.alcoholDegree}}"
                           name="specific"
                           [required]="unityForm.productForm.adhereToTheSectoralAgreement">
                    <div class="Unity-form-input-vcs-valid">
                      <img src="assets/icons/error.svg" alt="X"
                           [hidden]="unityForm.productForm.alcoholDegreeError.status !== 'warning'">
                      <img src="assets/icons/ok.svg" alt="OK"
                           [hidden]="unityForm.productForm.alcoholDegreeError.status !== 'valid'">
                    </div>
                  </div>
                  <div class="col-md-2 col-xs-2 label-volume-unit">% Vol</div>
                </div>
              </div>
              <!--container-->
              <div class="Unity-form-input mt-3 col-md-12 col-xs-12">
                <label for="container">Format de contenant
                  <span class="Unity-form-input--required">(obligatoire)</span></label>
                <div class="row">
                  <div class="Unity-form-input-gtin--prefix col-md-3 col-xs-6 text-center ">
                    <select name="container" id="container"
                            [(ngModel)]="unityForm.productForm.container"
                            [required]="unityForm.productForm.adhereToTheSectoralAgreement"
                            (change)="setAdhereToTheSectoralAgreement(false)">
                      <option value=""></option>
                      <option *ngFor="let container of unityForm.containerCodes"
                              [ngValue]="container.code">{{ container.wording }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!--sugar content -->
              <div class="Unity-form-input mt-3 col-md-12 col-xs-12"
                   *ngIf="unityForm.isProductAnyWine()">
                <label>Mention relative à la teneur en sucre
                  <span
                    class="Unity-form-input--required"
                    *ngIf="unityForm.isProductSparklingWine()">(obligatoire)</span></label>
                <div class="icon-help"
                     popoverTitle="{{unityForm.constants.inputsInfo.sugarContent.title}}"
                     popover="{{unityForm.constants.inputsInfo.sugarContent.content}}"
                     placement="right"
                     triggers="mouseenter:mouseleave">
                </div>
                <div class="row">
                  <div class="Unity-form-input-gtin--prefix col-md-3 col-xs-6 text-center ">
                    <select name="sugarContent" id="sugarContent"
                            [(ngModel)]="unityForm.productForm.sugarContent"
                            [required]="unityForm.isProductSparklingWine() && unityForm.productForm.adhereToTheSectoralAgreement"
                            (change)="setAdhereToTheSectoralAgreement(false)">
                      <option value=""></option>
                      <option *ngFor="let item of unityForm.sugarContentConstants"
                              [ngValue]="item.code">{{ item.wording }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- grapes-->
        <div class="Unity-form-section row" *ngIf="isWine">
          <div class="Unity-form-section-name col-md-2 col-xs-4">Cépages</div>
          <div class="Unity-form-section-content col-md-10 col-xs-8">
            <div class="row">
              <div class="Unity-form-input col-md-12 col-xs-12">
                <label>Cépages</label>
                <ng-selectize name="grapes" [config]="multiSelectConfig"
                              [options]="multiSelectOptions"
                              [placeholder]=unityForm.constants.createForm.placeholders.grapes
                              [(ngModel)]="unityForm.productForm.varietals"
                              (ngModelChange)="setAdhereToTheSectoralAgreement(false)"
                              ngDefaultControl></ng-selectize>
              </div>
            </div>
          </div>
        </div>


        <!--Certification et Labels section-->
        <div class="Unity-form-section row">
          <div class="Unity-form-section-name col-md-2 col-xs-4">Labels et Certifications</div>
          <div class="Unity-form-section-content col-md-10 col-xs-8">
            <div class="Unity-form-subSection row">
              <div class="Unity-form-input col-md-12 col-xs-12">
                <label>Certifications</label>
              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="AGRICULTURE_BIOLOGIQUE" value=true
                           [(ngModel)]="unityForm.productForm.certifications.AGRICULTURE_BIOLOGIQUE"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Agriculture biologique
                  </label>
                </div>
              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="HAUTE_VALEUR_ENVIRONNEMENTALE"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.HAUTE_VALEUR_ENVIRONNEMENTALE"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Haute Valeur Environnementale
                  </label>
                </div>

              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="DEMETER_LABEL"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.DEMETER_LABEL"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Demeter
                  </label>
                </div>

              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="EUROFEUILLE"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.EUROFEUILLE"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Eurofeuille
                  </label>
                </div>
              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="TERRA_VITIS"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.TERRA_VITIS"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Terra Vitis
                  </label>
                </div>
              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="VITICULTURE_DURABLE"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.VITICULTURE_DURABLE"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Viticulture durable
                  </label>
                </div>
              </div>
              <div class="mt-3 col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9  mt-1">
                            <span class="fw-normal">Autre(s)
                               <input type="text" name="AUTRE_CERTIF"
                                      value=true [(ngModel)]="unityForm.productForm.certifications.AUTRE_CERTIF"
                                      (keyup)="setAdhereToTheSectoralAgreement(false)">
                            </span>
              </div>

            </div>
            <div class="Unity-form-subSection row">
              <div class="Unity-form-input col-md-12 col-xs-12">
                <label>Labels</label>
              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="BIODYVIN"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.BIODYVIN"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Biodyvin
                  </label>
                </div>
              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="ECOCERT_CERTIFICATE"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.ECOCERT_CERTIFICATE"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Ecocert
                  </label>
                </div>
              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="NATURE_ET_PROGRES"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.NATURE_ET_PROGRES"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Nature et Progrès
                  </label>
                </div>

              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="ORIGINE_FRANCE_GARANTIE"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.ORIGINE_FRANCE_GARANTIE"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Origine France Garantie
                  </label>
                </div>
              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" name="VIN_NATUREL"
                           value=true
                           [(ngModel)]="unityForm.productForm.certifications.VIN_NATUREL"
                           (change)="setAdhereToTheSectoralAgreement(false)">
                    Vin naturel
                  </label>
                </div>
              </div>
              <div class="col-md-1 col-xs-3"></div>
              <div class="col-md-11 col-xs-9 mt-1">
                            <span class="fw-normal">Autre(s)
                              <input type="text" name="AUTRE_LABEL"
                                     value=true [(ngModel)]="unityForm.productForm.certifications.AUTRE_LABEL"
                                     (keyup)="setAdhereToTheSectoralAgreement(false)">
                            </span>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="Unity-footer">
      <div class="Unity-footer-buttons"
           *ngIf="unityForm.productForm.adhereToTheSectoralAgreement || unityForm.productForm.adhereToRegulatoryAgreement"
           role="group">
        <button gs1Button [appearance]="'outlined'" (click)="returnToPreviousStep(false)">Retour
        </button>
        <button type="submit" gs1Button *ngIf="showBtn(formProduct)">
          <span>Continuer</span>
        </button>

        <button type="button" gs1Button class="submitButton"
                tooltip="Veuillez renseigner tous les champs demandés."
                container="Unity-footer" *ngIf="!showBtn(formProduct)"
                [class.disabled]="hasSetAllRegulatoryAttribute() === false ||  formProduct.form.valid === false">
          <span>Continuer</span>
        </button>

      </div>
      <div class="Unity-footer-buttons" role="group"
           *ngIf="!unityForm.productForm.adhereToTheSectoralAgreement && !unityForm.productForm.adhereToRegulatoryAgreement">
        <button gs1Button [appearance]="'outlined'" (click)="returnToPreviousStep(false)">Retour
        </button>
        <button type="submit" gs1Button>
          <span>Passer cette étape</span>
        </button>
      </div>
    </div>
  </form>
</div>
