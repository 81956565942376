<div class="Unity-body">
  <!-- FORM -->
  <div class="Unity-form container-fluid">
    <!-- Introduction section -->
    <div class="Unity-form-section row reglementary-section">
      <div id="regulatory-agreement"  class="Unity-form-section-name section-title col-md-6 col-xs-6 reglementary-section-title">Informations réglementaires du produit
      </div>
      <gs1-link-card class="card">
        <p class="title">Vous souhaitez être en conformité avec la réglementation européenne sur les vins dès à présent ?</p>
        <p>GS1 France vous propose de renseigner la liste des ingrédients et les valeurs nutritionnelles
          pour générer le QR Code augmenté GS1 à imprimer sur les étiquettes.
        </p>

      </gs1-link-card>
      <!--                    <div class="Unity-form-section-content col-md-10 col-xs-8">-->
      <!--                        <p>{{unityForm.constants.createForm.introduction}}</p>-->
      <!--                    </div>-->

    </div>

    <!-- ingredientStatement -->
    <div class="Unity-form-section row">

      <div class="Unity-form-section-name col-md-12 col-xs-12">Composition</div>

      <div class="Unity-form-section-content col-md-12 col-xs-12 ingredientsArea">
        <!-- ingredientStatement -->
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-6 col-xs-6">
            <label for="ingredientStatement">
              Liste des ingrédients
            </label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.ingredientStatementVcs.title}}"
                 popover="{{unityForm.constants.inputsInfo.ingredientStatementVcs.content }}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="ingredientsContainer">
              <div class="textareaHeader" (click)="editableContent.focus();">Ingrédients:</div>
              <div class="textAreaContainer" (click)="editableContent.focus();" [class.ng-invalid]="ingredientStatement.validity.valid === false">
                <div class="textAreaVisible defineScrollbar" contenteditable="true"
                     #editableContent (keydown)="onKeyDwn(editableContent, $event)"
                     (keyup)="updateTextArea(editableContent, ingredientStatement, $event); setAdhereToRegulatoryAgreement()">{{ingredientPlaceholder}}</div>
                <textarea id="ingredientStatement" class="mt-2 ingredient"
                          maxlength="5000"
                          [(ngModel)]="unityForm.productForm.ingredientStatement"
                          [required]="unityForm.productForm.adhereToRegulatoryAgreement"
                          name="ingredientStatement" #ingredientStatement>
                                            </textarea>
                <div class="textAreaFooter" *ngIf="unityForm.productForm.ingredientStatement.length > 0"
                     (click)="editableContent.focus();">
                  <gs1-alert class="ingredientTextAreaFooterAlert" [title]="'Important !'" [showIcon]="false"
                             [content]="'Renseignez en majuscules les substances ou produits allergènes mis en ' +
                                  'évidence dans la liste des ingrédients présente sur l’emballage de la denrée alimentaire. La mise en majuscule ne doit être utilisée que pour les substances ou produits allergènes.'"
                             [type]="'warning'"></gs1-alert>
                </div>
              </div>
            </div>
            <div class="Unity-form-infoText">{{5000 - unityForm.productForm.ingredientStatement.length}}
              /5000
              {{unityForm.constants.createForm.helpers.inputTextInfoLength}}
            </div>
          </div>
          <div class="Unity-form-input col-md-6 col-xs-6 alert"
               *ngIf="ingredientStatement.validity.valid === false ">
            <gs1-alert [title]="'Veuillez remplir les champs pour continuer'" [showIcon]="false"
                       [content]="'Si vous souhaitez générer le QR Code augmenté GS1 de votre produit, tous les champs « Composition », « Allergènes » et « Valeurs nutritionnelles » doivent être renseignés.'"
                       [type]="'danger'"></gs1-alert>
          </div>
        </div>
      </div>
    </div>

 <!--Allergen section-->
 <div class="Unity-form-section row">
  <div class="Unity-form-section-name col-md-12">Allergènes</div>
  <div class="Unity-form-section-content col-md-12">
    <!--subsection VCS-->
    <div class="Unity-form-subSection row">
      <div class="allergens-base">
        <!--sulfit-->
        <div class="Unity-form-input col-md-12 col-xs-12">
          <label>Contient des sulfites</label>
          <div class="icon-help" popoverTitle="{{unityForm.constants.inputsInfo.allergen.AU.title}}"
            popover="{{unityForm.constants.inputsInfo.allergen.AU.content}}" placement="right"
            triggers="mouseenter:mouseleave">
          </div>
          <div class="checkbox">
            <label><input type="checkbox" name="sulfit" [value]=true [checked]="unityForm.productForm.allergen.AU==true"
                [required]="isAllergensRequired(unityForm.productForm.allergen.AU)" (click)="allergenClicked($event)">Oui</label>
          </div>
          <div class="checkbox">
            <label><input type="checkbox" name="sulfit" [checked]="unityForm.productForm.allergen.AU==false" [value]=false
                [required]="isAllergensRequired(unityForm.productForm.allergen.AU)" (click)="allergenClicked($event)">Non</label>
          </div>
        </div>
        <!--oeuf-->
        <div class="Unity-form-input col-md-12 col-xs-12">
          <label>Contient de l'œuf ou dérivé</label>
          <div class="icon-help" popoverTitle="{{unityForm.constants.inputsInfo.allergen.AE.title}}"
            popover="{{unityForm.constants.inputsInfo.allergen.AE.content}}" placement="right"
            triggers="mouseenter:mouseleave">
          </div>
          <div class="checkbox">
            <label><input type="checkbox" name="oeuf" [value]=true [checked]="unityForm.productForm.allergen.AE==true"
                [required]="isAllergensRequired(unityForm.productForm.allergen.AE)" (click)="allergenClicked($event)">Oui</label>
          </div>
          <div class="checkbox">
            <label><input type="checkbox" name="oeuf" [value]=false [checked]="unityForm.productForm.allergen.AE==false"
                [required]="isAllergensRequired(unityForm.productForm.allergen.AE)" (click)="allergenClicked($event)">Non</label>
          </div>
        </div>
        <!--lait-->
        <div class="Unity-form-input col-md-12 col-xs-12">
          <label>Contient du lait ou dérivé</label>
          <div class="icon-help" popoverTitle="{{unityForm.constants.inputsInfo.allergen.AM.title}}"
            popover="{{unityForm.constants.inputsInfo.allergen.AM.content}}" placement="right"
            triggers="mouseenter:mouseleave">
          </div>
          <div class="checkbox">
            <label><input type="checkbox" name="lait" [value]=true [checked]="unityForm.productForm.allergen.AM==true"
                [required]="isAllergensRequired(unityForm.productForm.allergen.AM)" (click)="allergenClicked($event)">Oui</label>
          </div>
          <div class="checkbox">
            <label><input type="checkbox" name="lait" [value]=false [checked]="unityForm.productForm.allergen.AM==false"
                [required]="isAllergensRequired(unityForm.productForm.allergen.AM)" (click)="allergenClicked($event)">Non</label>
          </div>
        </div>
      </div>
      <gs1-alert
        *ngIf=" (unityForm.productForm.adhereToTheSectoralAgreement || unityForm.productForm.adhereToRegulatoryAgreement || allergenIsSelected) && !hasSetAllAllergens() "
        [title]="'Veuillez remplir les champs pour continuer'" [showIcon]="false"
        [content]="'Si vous souhaitez générer le QR Code augmenté GS1 de votre produit, tous les champs « Composition », « Allergènes » et « Valeurs nutritionnelles » doivent être renseignés.'"
        [type]="'danger'"></gs1-alert>
    </div>
    <!--subsection SPI-->
    <div *ngIf="isSpi"
         class="Unity-form-subSection row">
      <!--gluten-->
      <div class="Unity-form-input col-md-12 col-xs-12">
        <label>Contient Céréale contenant du gluten</label>
        <div class="icon-help"
             popoverTitle="{{unityForm.constants.inputsInfo.allergen.AW.title}}"
             popover="{{unityForm.constants.inputsInfo.allergen.AW.content}}"
             placement="right"
             triggers="mouseenter:mouseleave">
        </div>
        <div class="checkbox">
          <label><input type="checkbox" name="gluten" [value]=true
            [checked]="unityForm.productForm.allergen.AW==true" [required]="isAllergensRequired(unityForm.productForm.allergen.AW)"
                            (click)="allergenClicked($event)"
                            >Oui</label>
        </div>
        <div class="checkbox">
          <label><input type="checkbox" name="gluten"
            [checked]="unityForm.productForm.allergen.AW==false"
                        [value]=false [required]="isAllergensRequired(unityForm.productForm.allergen.AW)"
                            (click)="allergenClicked($event)"
                            >Non</label>
        </div>
      </div>
      <!--crustacé-->
      <div class="Unity-form-input col-md-12 col-xs-12">
        <label>Contient des crustacés</label>
        <div class="icon-help"
             popoverTitle="{{unityForm.constants.inputsInfo.allergen.UN.title}}"
             popover="{{unityForm.constants.inputsInfo.allergen.UN.content}}"
             placement="right"
             triggers="mouseenter:mouseleave">
        </div>
        <div class="checkbox">
          <label><input type="checkbox" name="crustace"
                        [value]=true [checked]="unityForm.productForm.allergen.UN==true"
                        [required]="isAllergensRequired(unityForm.productForm.allergen.UN)"
                            (click)="allergenClicked($event)"
                            >Oui</label>
        </div>
        <div class="checkbox">
          <label><input type="checkbox" name="crustace"
                        [value]=false [checked]="unityForm.productForm.allergen.UN==false"
                        [required]="isAllergensRequired(unityForm.productForm.allergen.UN)"
                            (click)="allergenClicked($event)"
                            >Non</label>
        </div>
      </div>
      <!--arachide-->
      <div class="Unity-form-input col-md-12 col-xs-12">
        <label>Contient des arachides et produits à base d'arachides</label>
        <div class="icon-help"
             popoverTitle="{{unityForm.constants.inputsInfo.allergen.AP.title}}"
             popover="{{unityForm.constants.inputsInfo.allergen.AP.content}}"
             placement="right"
             triggers="mouseenter:mouseleave">
        </div>
        <div class="checkbox">
          <label><input type="checkbox" name="arachide"
                        [value]=true [checked]="unityForm.productForm.allergen.AP==true"
                        [required]="isAllergensRequired(unityForm.productForm.allergen.AP)"
                            (click)="allergenClicked($event)"
                            >Oui</label>
        </div>
        <div class="checkbox">
          <label><input type="checkbox" name="arachide"
                        [value]=false [checked]="unityForm.productForm.allergen.AP==false"
                        [required]="isAllergensRequired(unityForm.productForm.allergen.AP)"
                            (click)="allergenClicked($event)"
                            >Non</label>
        </div>
      </div>
      <!--fruit à coque-->
      <div class="Unity-form-input col-md-12 col-xs-12">
        <label>Contient des fruits à coque</label>
        <div class="icon-help"
             popoverTitle="{{unityForm.constants.inputsInfo.allergen.AN.title}}"
             popover="{{unityForm.constants.inputsInfo.allergen.AN.content}}"
             placement="right"
             triggers="mouseenter:mouseleave">
        </div>
        <div class="checkbox">
          <label><input type="checkbox" name="fruitCoque"
                        [value]=true [checked]="unityForm.productForm.allergen.AN==true"
                        [required]="isAllergensRequired(unityForm.productForm.allergen.AN)"
                            (click)="allergenClicked($event)"
                            >Oui</label>
        </div>
        <div class="checkbox">
          <label><input type="checkbox" name="fruitCoque"
                        [value]=false [checked]="unityForm.productForm.allergen.AN==false"
                        [required]="isAllergensRequired(unityForm.productForm.allergen.AN)"
                            (click)="allergenClicked($event)"
                            >Non</label>
        </div>
      </div>
    </div>
  </div>
</div>

    <!--Nutriments-->
    <div class="Unity-form-section row">
      <div class="Unity-form-section-name col-md-6 col-xs-6">Valeurs nutritionnelles
      </div>
      <div class="Unity-form-section-content col-md-12 col-xs-12 nutriValuesContainer">
        <div class="Unity-form-subSection row nutri-values">
          <div class="Unity-form-input col-xs-12 mt-3">
            <label>Valeurs nutritionnelles</label>
            <div class="icon-help"
                 popoverTitle="{{constants.inputsInfo.nutriment.title}}"
                 popover="{{constants.inputsInfo.nutriment.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="row ">
              <div class="labelContainer col-xs-2">
                <div class="col-xs-12 itemSpace"
                     *ngFor="let item of unityForm.productForm.nutriment.items; index as i">
                  <div class="row">
                    <div class="col-xs-2 valueLabel">
                      {{unityForm.findNutrimentWording(item.nutrientTypeCode,
                      item.measurementUnitCode)}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="valuesContainer col-xs-2">
                <div class="col-xs-12 text-center portion ">
                  {{unityForm.findServingSizeWording(unityForm.productForm.nutriment.measurementUnitCode)}}
                </div>
                <div class="col-xs-12 valuesColumn "
                     *ngFor="let item of unityForm.productForm.nutriment.items; index as i">
                  <div class="valuesCell">
                    <input class="specificWidth"
                           [required]="hasSetNutrients() || unityForm.productForm.adhereToRegulatoryAgreement"
                           type="text"
                           autocomplete="false"
                           (keyup)="checkValue($event)"
                           (input)="checkEnergeticValue(item, 'quantityContained'); resetNutrimentClass(item); setAdhereToRegulatoryAgreement()"
                           [(ngModel)]="item.quantityContained"
                           maxlength="10"
                           id="quantityContained_{{item.nutrientTypeCode}}"
                           name="quantityContained_{{i}}" #name="ngModel"
                           [ngClass]="{'error': item.hasErrorQuantityContained()}">
                  </div>
                  <div class="valuesUnit">
                    {{unityForm.findMeasurementUnitWording(item.measurementUnitCode)}}
                  </div>
                </div>
              </div>
              <div class="col-md-6 removeNutrimentContainer">
                <div class="col-md-12 col-xs-12"
                     *ngIf=" !hasSetAllNutrients() && unityForm.productForm.adhereToRegulatoryAgreement">
                  <gs1-alert [title]="'Veuillez remplir les champs pour continuer'" [showIcon]="false"
                             [content]="'Si vous souhaitez générer le QR Code augmenté GS1 de votre produit, tous les champs « Composition », « Allergènes » et « Valeurs nutritionnelles » doivent être renseignés.'"
                             [type]="'danger'"></gs1-alert>
                </div>
              </div>
              <div class="Unity-form-input col-xs-10 mt-3">

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <!-- Introduction sectorial section -->
    <div class="Unity-form-section sectorial-section row">
      <div class="Unity-form-section-name section-title col-md-6 col-xs-6">Informations sectorielles du produit
      </div>
      <!--                    <div class="Unity-form-section-content col-md-10 col-xs-8">-->
      <!--                        <p>{{unityForm.constants.createForm.introduction}}</p>-->
      <!--                    </div>-->

    </div>
    <!-- spirits type section -->
    <div class="Unity-form-section row" *ngIf="isSpi">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Spiritueux</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-12 col-xs-12">
            <div class="Unity-form-input">
              <label for="typeOfSpirits">Type de spiritueux <span
                class="Unity-form-input--required">(obligatoire)</span></label>
              <div class="row">
                <div class="Unity-form-input-gtin--prefix col-xs-3 text-center ">
                  <select name="typeOfSpirits" id="typeOfSpirits"
                          [required]="unityForm.productForm.adhereToTheSectoralAgreement"
                          (change)="setAdhereToTheSectoralAgreement(false)"
                          [(ngModel)]="unityForm.productForm.spiritCodeGpc">
                    <option value=""></option>
                    <option *ngFor="let spirit of unityForm.spirits"
                            [ngValue]="spirit.code">
                      {{spirit.wording}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="Unity-form-input mt-3 col-md-12 col-xs-122">
            <div class="Unity-form-input">
              <label>Style de spiritueux </label>
              <div class="row">
                <div class="Unity-form-input-gtin--prefix col-xs-3 text-center ">
                  <select name="spiritStyle" id="spiritStyle"
                          [(ngModel)]="unityForm.productForm.spiritStyle"
                          (change)="setAdhereToTheSectoralAgreement(false)">
                    <option value=""></option>
                    <option *ngFor="let spiritStyle of unityForm.spiritStyles"
                            [ngValue]="spiritStyle.code">
                      {{spiritStyle.wording}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Origin Country -->
    <div class="Unity-form-section row">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Origine</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-6 col-xs-12">
            <label for="country">Pays d'origine <span
              class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.country.title}}"
                 popover="{{unityForm.constants.inputsInfo.country.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-xs-6 text-center ">
                <select name="country" id="country"
                        [required]="unityForm.productForm.adhereToTheSectoralAgreement"
                        (change)="setAdhereToTheSectoralAgreement(false)"
                        [(ngModel)]="unityForm.productForm.productCountry">
                  <option *ngFor="let country of unityForm.countries"
                          [ngValue]="country.code">{{country.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--wine color-->
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12" *ngIf="showIG()">
            <label>Signe d'indication géographique</label>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-4 col-xs-6 text-center ">
                <select name="gis" id="gis"
                        [(ngModel)]="unityForm.productForm.gis" (change)="setAdhereToTheSectoralAgreement(false)">
                  <option value=""></option>
                  <option *ngFor="let gis of gisCodes"
                          [ngValue]="gis.code">{{gis.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- AOC -->
          <div class="Unity-form-input  mt-3 col-md-6 col-xs-12"
               *ngIf="showAOC()">
            <label>Indication géographique</label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.aoc.title}}"
                 popover="{{unityForm.constants.inputsInfo.aoc.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-12 col-xs-12 text-center ">
                <ng-selectize name="aoc" [config]="aocSelectConfig" style="text-transform: uppercase;"
                              [options]="aocSelectOptions"
                              [(ngModel)]="unityForm.productForm.aoc"
                              (ngModelChange)="setAdhereToTheSectoralAgreement(false)"
                              ngDefaultControl></ng-selectize>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Specific section -->
    <div class="Unity-form-section row">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Sp&eacute;cificit&eacute;s</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="Unity-form-subSection row">
          <!--Main-->
          <!--age of the spirit-->
          <div class="Unity-form-input col-md-12 col-xs-12"
               *ngIf="isSpi">
            <label>&Acirc;ge du spiritueux</label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.ageOfSpirit.title}}"
                 popover="{{unityForm.constants.inputsInfo.ageOfSpirit.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="Unity-form-input-vcs row">
              <div class="col-md-2 col-xs-5">
                <select name="ageOfSpirit" id="ageOfSpirit"
                        [(ngModel)]="unityForm.productForm.ageOfSpirit" (change)="setAdhereToTheSectoralAgreement(false)">
                  <option value=""></option>
                  <option *ngFor="let nbYear of nbYears"
                          [ngValue]="nbYear">{{nbYear}}
                  </option>
                </select>
              </div>
              <div class="col-md-2 col-xs-2">Ans</div>
            </div>
          </div>
          <!--vintage section-->
          <div [class.mt-3]="isSpi"
               class="Unity-form-input col-md-12 col-xs-12">
            <label for="vintage">Millésime <span
              class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-2 col-xs-6 text-center ">
                <select name="vintage" id="vintage"
                        [required]="unityForm.productForm.adhereToTheSectoralAgreement"
                        (change)="setAdhereToTheSectoralAgreement(true)"
                        [(ngModel)]="unityForm.productForm.vintage">
                  <option value=""></option>
                  <option *ngFor="let vintage of vintageSvc.vintages"
                          [ngValue]="vintage.code">{{vintage.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!--wine color-->
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12"
               *ngIf="isWine">
            <label>Couleur du vin
              <span class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-2 col-xs-6 text-center ">
                <select name="wineColor" id="wineColor"
                        [required]="isWine && unityForm.productForm.adhereToTheSectoralAgreement"
                        [(ngModel)]="unityForm.productForm.wineColor"
                        (change)="setAdhereToTheSectoralAgreement(false)">
                  <option value=""></option>
                  <option *ngFor="let wineColor of unityForm.wineColorCodes"
                          [ngValue]="wineColor.code">{{wineColor.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12"
               [ngClass]="'formError-' + unityForm.productForm.alcoholDegreeError.status">
            <label for="specific">Quantit&eacute; d'alcool <span
              class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.alcoholDegree.title}}"
                 popover="{{unityForm.constants.inputsInfo.alcoholDegree.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="Unity-form-input-vcs row">
              <div class="col-md-2 col-xs-5">
                <input id="specific" type="text" maxlength="4"
                       [(ngModel)]="unityForm.productForm.alcoholDegree"
                       (ngModelChange)="unityForm.onAlcoholDegreeChange($event); setAdhereToTheSectoralAgreement(false)"
                       pattern="{{unityForm.DECIMAL_PATTERN}}"
                       placeholder="{{unityForm.constants.createForm.placeholders.alcoholDegree}}"
                       name="specific"
                       [required]="unityForm.productForm.adhereToTheSectoralAgreement">
                <div class="Unity-form-input-vcs-valid">
                  <img src="assets/icons/error.svg" alt="X"
                       [hidden]="unityForm.productForm.alcoholDegreeError.status !== 'warning'">
                  <img src="assets/icons/ok.svg" alt="OK"
                       [hidden]="unityForm.productForm.alcoholDegreeError.status !== 'valid'">
                </div>
              </div>
              <div class="col-md-2 col-xs-2 label-volume-unit">% Vol</div>
            </div>
          </div>
          <!--container-->
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12">
            <label for="container">Format de contenant
              <span class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-2 col-xs-6 text-center ">
                <select name="container" id="container"
                        [(ngModel)]="unityForm.productForm.container"
                        [required]="unityForm.productForm.adhereToTheSectoralAgreement"
                        (change)="setAdhereToTheSectoralAgreement(false)">
                  <option value=""></option>
                  <option *ngFor="let container of containerCodes"
                          [ngValue]="container.code">{{container.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!--sugar content -->
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12"
               *ngIf="unityForm.isProductAnyWine()">
            <label>Mention relative à la teneur en sucre
              <span
                class="Unity-form-input--required"
                *ngIf="unityForm.isProductSparklingWine()">(obligatoire)</span></label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.sugarContent.title}}"
                 popover="{{unityForm.constants.inputsInfo.sugarContent.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-2 col-xs-6 text-center ">
                <select name="sugarContent" id="sugarContent"
                        [(ngModel)]="unityForm.productForm.sugarContent"
                        [required]="unityForm.isProductSparklingWine() && unityForm.productForm.adhereToTheSectoralAgreement"
                        (change)="setAdhereToTheSectoralAgreement(false)">
                  <option value=""></option>
                  <option *ngFor="let item of unityForm.sugarContentConstants"
                          [ngValue]="item.code">{{item.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- grapes-->
    <div class="Unity-form-section row" *ngIf="isWine">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Cépages</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="row">
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Cépages</label>
            <ng-selectize name="grapes" [config]="multiSelectConfig" [options]="multiSelectOptions"
                          [placeholder]=unityForm.constants.createForm.placeholders.grapes
                          [(ngModel)]="unityForm.productForm.varietals"
                          (ngModelChange)="setAdhereToTheSectoralAgreement(false)"
                          ngDefaultControl></ng-selectize>
          </div>
        </div>
      </div>
    </div>

    <!--Certification et Labels section-->
    <div class="Unity-form-section row">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Labels et Certifications</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Certifications</label>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="AGRICULTURE_BIOLOGIQUE" value=true
                       [(ngModel)]="unityForm.productForm.certifications.AGRICULTURE_BIOLOGIQUE" (change)="setAdhereToTheSectoralAgreement(false)">
                Agriculture biologique
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="HAUTE_VALEUR_ENVIRONNEMENTALE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.HAUTE_VALEUR_ENVIRONNEMENTALE" (change)="setAdhereToTheSectoralAgreement(false)">
                Haute Valeur Environnementale
              </label>
            </div>

          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="DEMETER_LABEL"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.DEMETER_LABEL" (change)="setAdhereToTheSectoralAgreement(false)">
                Demeter
              </label>
            </div>

          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="EUROFEUILLE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.EUROFEUILLE" (change)="setAdhereToTheSectoralAgreement(false)">
                Eurofeuille
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="TERRA_VITIS"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.TERRA_VITIS" (change)="setAdhereToTheSectoralAgreement(false)">
                Terra Vitis
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="VITICULTURE_DURABLE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.VITICULTURE_DURABLE" (change)="setAdhereToTheSectoralAgreement(false)">
                Viticulture durable
              </label>
            </div>
          </div>
          <div class="mt-3 col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9  mt-1">
                    <span class="fw-normal">Autre(s)
                       <input type="text" name="AUTRE_CERTIF"
                              value=true [(ngModel)]="unityForm.productForm.certifications.AUTRE_CERTIF" (keyup)="setAdhereToTheSectoralAgreement(false)">
                    </span>
          </div>

        </div>
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Labels</label>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="BIODYVIN"
                       value=true [(ngModel)]="unityForm.productForm.certifications.BIODYVIN"  (change)="setAdhereToTheSectoralAgreement(false)">
                Biodyvin
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="ECOCERT_CERTIFICATE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.ECOCERT_CERTIFICATE"  (change)="setAdhereToTheSectoralAgreement(false)">
                Ecocert
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="NATURE_ET_PROGRES"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.NATURE_ET_PROGRES" (change)="setAdhereToTheSectoralAgreement(false)">
                Nature et Progrès
              </label>
            </div>

          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="ORIGINE_FRANCE_GARANTIE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.ORIGINE_FRANCE_GARANTIE" (change)="setAdhereToTheSectoralAgreement(false)">
                Origine France Garantie
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="VIN_NATUREL"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.VIN_NATUREL" (change)="setAdhereToTheSectoralAgreement(false)">
                Vin naturel
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9 mt-1">
                  <span class="fw-normal">Autre(s)
                    <input type="text" name="AUTRE_LABEL"
                           value=true [(ngModel)]="unityForm.productForm.certifications.AUTRE_LABEL" (keyup)="setAdhereToTheSectoralAgreement(false)">
                  </span>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
