<div class="header">
  <div class="modal-title"></div>
  <div class="header-menu-links">
    <div class="col-md-12 headerButtonsContainer centerBlock">
      <button *ngIf="!isGtin8()" type="button" class="header-menu-button barcode-btn" (click)="generateBarcode()"
              [disabled]="product.oldCodeGpc">
        <img src="assets/icons/upc.svg" alt="Créer code-barres"
             tooltip="Créer le codes-barres"><span>Créer le code-barres</span>
      </button>
      <button type="button" class="header-menu-button qrcode-btn" (click)="openQRcodeModal()"
              [disabled]="product.oldCodeGpc">
        <img src="assets/icons/qr-code.svg" alt="Créer QR Code"
             tooltip="Créer le QR Code augmenté GS1"><span>Créer le QR Code augmenté GS1</span>
      </button>
      <qr-code-creation></qr-code-creation>
    </div>

    <div class="col-md-3  visibilityContainer"
         *ngIf="featureToggleSvc.canSeeChangeVisibility() && !productsSvc.isRiskyProduct(productForm.productResellerType) && !product.oldCodeGpc">
      <div class="icon-help pull-right"
           matTooltip="Publier ce produit rend visible ses informations clés aux adhérents ayant accès aux registres GS1."
           matTooltipClass="mat-tooltip-bottom-arrow-top-right">
      </div>
      <ui-switch class="pull-right" name="productVisibility" [checked]="!productForm.isPrivate"
                 (valueChange)="onVisibilityChange($event)">
      </ui-switch>

      <div *ngIf="!productForm.isPrivate" class="pull-right visibilityState">GTIN publié</div>
      <div *ngIf="productForm.isPrivate" class="pull-right visibilityState">GTIN non publié</div>
    </div>
  </div>
</div>
<div class="defineScrollbar">
  <div class="container">

    <div class="row" *ngIf="isAdhereToRegulatoryAgreement()">
      <div class="col-lg-12 col-md-12 col-xs-12 separator">
      </div>
    </div>

    <div class="row" *ngIf="isAdhereToRegulatoryAgreement()">
      <div class="col-lg-2 col-md-2 col-xs-2">
      </div>
      <div class="col-lg-8 col-md-8 col-xs-8">
        <gs1-alert class="reglementary-success-alert" [title]="'La carte d’identité de votre produit est complète !'"
                   [showIcon]="false"
                   [content]="gs1AlertContent"
                   [type]="'success'"></gs1-alert>
        <br/>
      </div>


    </div>
    <div class="row" *ngIf="!isAdhereToRegulatoryAgreement()">
      <div class="col-lg-12 col-md-12 col-xs-12 separator"  >
      </div>
    </div>
    <!-- NOM PRODUIT ----->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 textTitle">{{productForm.getProductName(languageProductName)}}</div>
    </div>
    <!-- LANGUAGE NOM PRODUIT ----->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12">
                        <span *ngFor="let lang of productForm.getProductNamesLanguage();index as index;">
                            <span
                              [className]="languageProductName === lang ? 'lang-section selected-lang' : 'lang-section'"
                              (click)="languageProductName = lang">{{lang}}</span>
                            <span
                              *ngIf="productForm.getProductNamesLanguage().length > 1 && index !== productForm.getProductNamesLanguage().length - 1"> | </span>
                        </span>

      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12" style="height: 50px">
        <div class="product-buttons">
          <button type="button" class="product-button" (click)="editProduct()" [disabled]="product.oldCodeGpc">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
              <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
            </svg>
            <span>Modifier</span>
          </button>
          <button type="button" class="product-button" (click)="shareCIP()"
                  [disabled]="productForm.isPrivate || product.oldCodeGpc">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
              <path
                d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
            </svg>
            <span>Partager</span>
          </button>

          <button type="button" class="product-button" (click)="confirmationModal.openModal()"
                  [disabled]="product.oldCodeGpc" *ngIf="!environment.HIDE_DELETE_BTN_PRODUCT">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
            <span>Supprimer</span>
          </button>
        </div>
      </div>
    </div>
    <!-- INFO VCS ----->
    <div class="row">
      <!-- IMAGE ----->
      <div class="col-lg-4 col-md-4 col-xs-12 section">
        <div class="imgCarousel" *ngIf="showSubSection(extractUrlFromImage())"
             [className]="isProductWine() && showSubSection(productForm.vintageName)? 'imgCarousel withVintage': 'imgCarousel'">
          <carousel>
            <slide *ngFor="let image of imagesToDisplay">
              <div class="carousel-image">
                <img alt="url" src="{{image['url']}}">
              </div>
            </slide>
          </carousel>
        </div>
        <div class="col-sm-12 text-center" *ngIf="!showSubSection(extractUrlFromImage())"
             style="height: 100%">
          <img class="noPicture"
               [className]="isProductWine() && showSubSection(productForm.vintageName)? 'noPicture withVintage': 'noPicture'"
               src="/assets/img/nopicture.png" alt="noSlide">
        </div>
      </div>

      <div class="col-lg-7 col-md-7 col-xs-12 col-md-push-1 section">

        <div class="row" style="padding-left: 1em;">
          <div class="col-lg-9 col-md-9 col-xs-12">
            <label class="col-xs-offset-1 gtinInfo">
              Global Trade Item Number (GTIN)
            </label>
            <!-- GTIN ----->
            <div class="col-xs-offset-1 ">
              {{productForm.gtin.prefix}}{{productForm.gtin.cip}}{{productForm.gtin.key}}
              <button
                class="copyGtinBtn"
                #btn
                (click)="clickCopyGtin($event, btn)"
                type="button"
                ngxClipboard
                [cbContent]="product.gtin"
                tooltip="{{ copyGtinToolTip }}"
                placement="right">
                <img alt="copy" src="assets/icons/icon-copy.png"/>
              </button>
            </div>
          </div>

        </div>
        <div class="col-xs-offset-1"
             style="border-bottom: solid rgba(228, 228, 228, 1) 1px; height: 2em">
        </div>

        <!-- CUVEE ----->
        <div>
          <label class="col-xs-offset-1">
            Classification (GPC-GS1) 
          </label>

          <div class="text col-xs-offset-1 ">
            {{findWordingFromCode(gpcCodes, productForm.codeGpc) || productForm.category}}
          </div>
        </div>

        <!-- TYPE ----->
        <div *ngIf="isProductSpi()">
          <label class="col-xs-offset-1">
            Type de spiritueux
          </label>

          <div class="text col-xs-offset-1 ">
            {{findWordingFromCode(spirits, productForm.spiritCodeGpc)}}
          </div>
        </div>

        <!-- PAYS DE VENTE ----->
        <div>
          <label class="col-xs-offset-1">
            PAYS DE VENTE
          </label>

          <div class="text col-xs-offset-1 targetMarket">
            {{constructTargetMarketWording()}}
          </div>
        </div>

        <!-- MARQUE ----->
        <label class="col-xs-offset-1">
          Marque(s)
        </label>
        <div class="text col-xs-offset-1 lastText" *ngIf="!cannotSeeBrand(productForm)">
          {{productForm.brand}} {{productForm.subBrand}}
        </div>
        <div class="text col-xs-offset-1 lastText" *ngIf="cannotSeeBrand(productForm)">
          SANS MARQUE
        </div>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 sectionSeparator">
      </div>
    </div>


    <!-- PAYS D'ORIGINE / COULEUR / MILLESISME ----->
    <div class="row" *ngIf="isProductWine()">
      <div class="col-lg-5 col-md-5 col-xs-12 section">
        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Pays d'origine
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{findWordingFromCode(countries, productForm.productCountry)}}
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 text-center"
             *ngIf="showAOC() && showSubSection(productForm.aoc)">
          <label class="col-xs-offset-0 vcsLabel">
            Indication géographique
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs" style="text-transform: uppercase;">
            {{findWordingFromCode(aocSelectOptions, productForm.aoc)}}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12"
             style="border-bottom: solid rgba(228, 228, 228, 1) 1px; height: 2em">

        </div>

        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Couleur du vin
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ findWordingFromCode(wineColorCodes, productForm.wineColor) }}
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Millésime
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ findWordingFromCode(vintageSvc.vintages, productForm.vintage) }}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12" *ngIf="showSection(productForm.varietals)"
             style="border-bottom: solid rgba(228, 228, 228, 1) 1px; height: 2em">
        </div>

        <div class="col-lg-12 col-md-12 col-xs-12" *ngIf="showSection(productForm.varietals)">
          <label class="col-xs-offset-0 vcsLabel">
            Cépages
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ findListWording(multiSelectOptions, productForm.varietals)}}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12"
             style="height: 2em">
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-xs-12 section  col-md-push-2">
        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Contenance
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{constructNetContentWording()}}
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Format de contenant
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ findWordingFromCode(containerCodes, productForm.container) }}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12"
             style="border-bottom: solid rgba(228, 228, 228, 1) 1px; height: 2em">

        </div>

        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Quantité d'alcool
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ productForm.alcoholDegree }} % Vol
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 text-center"
             *ngIf="isProductAnyWine() && showSubSection(findWordingFromCode(sugarContentConstants, productForm.sugarContent))">
          <label class="col-xs-offset-0 center-block vcsLabel">
            Mention relative à teneur en sucre
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ findWordingFromCode(sugarContentConstants, productForm.sugarContent) }}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12"
             style="height: 2em">
        </div>
      </div>

    </div>
    <div class="row" *ngIf="isProductSpi()">
      <div class="col-lg-5 col-md-5 col-xs-12 section">
        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Pays d'origine
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{findWordingFromCode(countries, productForm.productCountry)}}
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Millésime
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ findWordingFromCode(vintageSvc.vintages, productForm.vintage) }}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12"
             style="border-bottom: solid rgba(228, 228, 228, 1) 1px; height: 2em">

        </div>

        <div class="col-lg-6 col-md-6 col-xs-12 text-center" *ngIf="showSubSection(productForm.ageOfSpirit)">
          <label class="col-xs-offset-0 vcsLabel">
            &Acirc;ge du spiritueux
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            <div>
              {{ productForm.ageOfSpirit }} Ans
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 text-center"
             *ngIf="showSubSection(findWordingFromCode(spiritStyles, productForm.spiritStyle))">
          <label class="col-xs-offset-0 vcsLabel">
            Style de spiritueux
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{findWordingFromCode(spiritStyles, productForm.spiritStyle)}}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12"
             style="border-bottom: solid rgba(228, 228, 228, 1) 1px; height: 2em"
             *ngIf="showSection([findWordingFromCode(spiritStyles, productForm.spiritStyle), productForm.ageOfSpirit])">
        </div>


      </div>
      <div class="col-lg-5 col-md-5 col-xs-12 section  col-md-push-2">
        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Contenance
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{constructNetContentWording()}}
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Format de contenant
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ findWordingFromCode(containerCodes, productForm.container) }}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12"
             style="border-bottom: solid rgba(228, 228, 228, 1) 1px; height: 2em">

        </div>

        <div class="col-lg-6 col-md-6 col-xs-12 text-center">
          <label class="col-xs-offset-0 vcsLabel">
            Quantité d'alcool
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ productForm.alcoholDegree }} % Vol
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 text-center"
             *ngIf="isProductAnyWine() && showSubSection(findWordingFromCode(sugarContentConstants, productForm.sugarContent))">
          <label class="col-xs-offset-0 center-block vcsLabel">
            Mention relative à teneur en sucre
          </label>
          <div class="lastText col-xs-offset-0 lastTextVcs">
            {{ findWordingFromCode(sugarContentConstants, productForm.sugarContent) }}
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12"
             style="height: 2em">
        </div>
      </div>

    </div>
    <div class="row" *ngIf="(showSection(constructAllergenWording()) && !this.productForm.adhereToRegulatoryAgreement) ">
      <div class="col-lg-12 col-md-12 col-xs-12 sectionSeparator">
      </div>
    </div>

    <!-- Allergene ----->
    <div class="row" *ngIf="(showSection(constructAllergenWording()) && !this.productForm.adhereToRegulatoryAgreement)">
      <div [className]="showSection(constructAllergenWording()) ? 'col-lg-12 col-md-12 col-xs-12 section': 'col-lg-12 col-md-12 col-xs-12 section attribut-disabled'">
        <label  [className]="showSection(constructAllergenWording()) ? 'col-xs-offset-0 vcsLabel': 'col-xs-offset-0 vcsLabel label-disabled'">
          Allergènes :
        </label>
        <div class="lastText col-xs-offset-0 lastTextVcs">
          {{constructAllergenWording()}}
          <br>
          <br>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 sectionSeparator">
      </div>
    </div>
    <!-- INGREDIENTS ----->
    <div class="row">
      <div [className]="showIngredients() ? 'col-lg-12 col-md-12 col-xs-12 section': 'col-lg-12 col-md-12 col-xs-12 section attribut-disabled'">
        <label [className]="showIngredients() ? 'col-xs-offset-0 vcsLabel': 'col-xs-offset-0 vcsLabel label-disabled'">
          Ingrédients:
        </label>
        <div class="lastText col-xs-offset-0 lastTextVcs" *ngIf="showIngredients()">
          {{this.productForm.ingredientStatement}}
          <br>
          <br>
        </div>
        <div class="lastText col-xs-offset-0 lastTextVcs label-disabled" *ngIf="!showIngredients()">
          -
          <br>
          <br>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="(showSection(constructAllergenWording()) && this.productForm.adhereToRegulatoryAgreement) || !showSection(constructAllergenWording())">
      <div class="col-lg-12 col-md-12 col-xs-12 sectionSeparator">
      </div>
    </div>

    <!-- Allergene ----->
    <div class="row" *ngIf="(showSection(constructAllergenWording()) && this.productForm.adhereToRegulatoryAgreement) || !showSection(constructAllergenWording())">
      <div [className]="showSection(constructAllergenWording()) ? 'col-lg-12 col-md-12 col-xs-12 section': 'col-lg-12 col-md-12 col-xs-12 section attribut-disabled'">
        <label  [className]="showSection(constructAllergenWording()) ? 'col-xs-offset-0 vcsLabel': 'col-xs-offset-0 vcsLabel label-disabled'">
          Allergènes :
        </label>
        <div class="lastText col-xs-offset-0 lastTextVcs">
          {{constructAllergenWording()}}
          <br>
          <br>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="showNutrients()">
      <div class="col-lg-12 col-md-12 col-xs-12 sectionSeparator">
      </div>
    </div>
    <div class="row">
      <div [className]="isAdhereToRegulatoryAgreement() ? 'col-lg-12 col-md-12 col-xs-12 section': 'col-lg-12 col-md-12 col-xs-12 section attribut-disabled'">
        <label [className]="isAdhereToRegulatoryAgreement() ? 'col-xs-offset-0 vcsLabel': 'col-xs-offset-0 vcsLabel label-disabled'">
          Valeurs nutritionnelles
        </label>
        <div class="lastText col-xs-offset-0 lastTextVcs">
          <div class="nutrimentsTableContainer">
            <table class="nutrimentsTable">
              <thead>
              <tr>
                <th class="col-md-3 textCenter">
                </th>
                <th class="col-md-3 textCenter">
                  Pour {{productForm.nutriment.servingSize}}{{findMeasurementShortUnitWording(productForm.nutriment.measurementUnitCode)}}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let nutriment of productForm.nutriment.items">
                <td
                  class="col-md-3">{{findNutrimentWording(nutriment.nutrientTypeCode, nutriment.measurementUnitCode)}}</td>
                <td class="col-md-3" *ngIf="isAdhereToRegulatoryAgreement()">{{nutriment.quantityContained}} <span
                  class="unit">{{findMeasurementShortUnitWording(nutriment.measurementUnitCode)}}</span>
                </td>
                <td class="col-md-3" *ngIf="!isAdhereToRegulatoryAgreement()"> - </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <regulatory-modal [product]="product" class="regulatory" *ngIf="!isAdhereToRegulatoryAgreement()"></regulatory-modal>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 sectionSeparator">
      </div>
    </div>
    <!-- LABELS ET CERTIFICATIONS ----->
    <div class="row" *ngIf="showLabelAndCertifSection()">
      <div class="col-lg-12 col-md-12 col-xs-12 section">
        <label class="col-xs-offset-0 vcsLabel">
          Labels et Certifications:
        </label>
        <div class="lastText col-xs-offset-0 lastTextVcs">
          {{constructCertifWording()}}, {{constructLabelWording()}}
          <br>
          <br>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="showLabelAndCertifSection()">
      <div class="col-lg-12 col-md-12 col-xs-12 sectionSeparator">
      </div>
    </div>
    <!-- MPN ----->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 section" *ngIf="showMpnAndSkuSection()">
        <div *ngIf="showMpnSubSection()">
          <label class="col-xs-offset-0">
            Référence fabricant ou MPN
          </label>

          <div class="text col-xs-offset-0">
            {{productForm.mpn}}
          </div>
        </div>
        <div *ngIf="showSubSection(productForm.webLink)">
          <label class="col-xs-offset-0">
            Lien / url
          </label>

          <div class="lastText col-xs-offset-0">
            <a href="{{productForm.webLink}}" target="_blank">{{productForm.webLink}}</a>
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12"
               style="border-bottom: solid rgba(228, 228, 228, 1) 1px; height: 2em">
          </div>

        </div>
        <div *ngIf="showSubSection(productForm.sku)">
          <label class="col-xs-offset-0">
            Code interne ou SKU
          </label>

          <div class="col-xs-offset-0 lastText">
            {{productForm.sku}}
          </div>
        </div>
        <br>
        <br>
      </div>
    </div>
    <!-- SKU ----->
    <div class="row" *ngIf="showSection([productForm.mpn, productForm.sku])">
      <div class="col-lg-12 col-md-12 col-xs-12" style="height: 50px">
      </div>
    </div>


    <!-- COMPANY LABEL ----->
    <div class="row">
      <div class="companyLabel">
        Les droits d'utilisation du GTIN sont détenus par :
      </div>
    </div>
    <br>

    <!-- COMPANY SECTION ----->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 companySection">
        <label class="col-xs-offset-0">
          Global Location Number (GLN)
        </label>

        <div class="text col-xs-offset-0">
          {{companySvc.company.gln}}
        </div>
        <label class="col-xs-offset-0">
          Raison sociale
        </label>

        <div class="text col-xs-offset-0">
          {{companySvc.company.companyName}}
        </div>

        <label class="col-xs-offset-0">
          Adresse
        </label>

        <div class="col-xs-offset-0"
             [className]="'col-xs-offset-0 lastText'">
          {{companySvc.company.street}} {{companySvc.company.cp}} {{companySvc.company.city}}

        </div>

        <br>
        <br>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 sectionSeparator">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 sectionSeparator">
      </div>
    </div>
  </div>
</div>

<confirmation-modal [isDeleteDialog]="true" #confirmationModal [cancelBtnLabel]="'Annuler'" [submitBtnLabel]="'Valider'"
                    [title]="constants.deleteProduct.title" [body]="constants.deleteProduct.message"
                    (onConfirm)="deleteProduct()"></confirmation-modal>

<share-modal #shareModal [url]="shareUrl"></share-modal>
