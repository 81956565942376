import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Category} from '../models';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import * as _ from 'lodash';

@Injectable()
export class ProductCategorySvc {
  public category = new Category('', []);
  public categories: Category[] = [];

  constructor(private httpClient: HttpClient) {
  }

  public getCategoriesData(): Observable<Category[]> {
    const requestUrl = environment.API_URL + '/gpc';
    return this.httpClient.get(requestUrl).pipe(
      map(
        (data: any) => {
          this.categories = data.categories || [];
          return this.categories;
        }
      ));
  }

  public search = (query: any, classificationFilter: readonly string[] = []): Promise<any[]> => {
    let requestUrl = environment.API_URL + `/search/productCategories?searchQuery=${query}`;
    if (classificationFilter && classificationFilter.length > 0){
      const classificationFilterQuery  = `&classification=${classificationFilter}`;
      requestUrl += classificationFilterQuery;
    }
    return new Promise<any[]>((resolve, reject) => {
      this.httpClient.get(requestUrl)
        .subscribe((res: any) => {
          return resolve(res);
        }, err => {
          return reject(err);
        });
    });
  }

  public searchByTypeAndGpcCode = (type: string, code: string = ''): Promise<any[]> => {
    let requestUrl = environment.API_URL + `/search/productCategories/${type}`;
    if (code) {
      requestUrl = environment.API_URL + `/search/productCategories/${type}?gpcCode=${code}`;
    }
    return new Promise<any[]>((resolve, reject) => {
      this.httpClient.get(requestUrl)
        .subscribe((res: any) => {
          return resolve(res);
        }, err => {
          return reject(err);
        });
    });
  }

  public getCategory(gpcCode: string): Promise<Category> {
    const requestUrl = environment.API_URL + '/gpc/' + gpcCode;
    return new Promise<Category>((resolve, reject) => {
      this.httpClient.get(requestUrl)
        .subscribe((category: any) => {
          this.category = new Category(category.type, category.codes);
          return resolve(category);
        }, err => {
          return reject(err);
        });
    });
  }

  public getCategoriesWithGpc(gpcList: []) {

    const requestUrl = environment.API_URL + '/search/productCategories/gpc/codes';
    const body = {
      gpcCodes: gpcList
    };

    return new Promise<Category[]>((resolve, reject) => {
      this.httpClient.post(requestUrl, body)
        .subscribe((categoryList: any) => {
          return resolve(categoryList);
        }, err => {
          return reject(err);
        });
    });
  }

  public vcsClassification() {
    const spiObject = _.find(this.categories, c => c.type === 'SPI') || {codes: []};
    const vinLiqueurObject: any = _.find(this.categories, c => c.type === 'VIN_DE_LIQUEUR') || {codes: []};
    const vinEffervescentObject: any = _.find(this.categories, c => c.type === 'VIN_EFFERVESCENT') || {codes: []};
    const vinTranquilleObject: any = _.find(this.categories, c => c.type === 'VIN_TRANQUILLE') || {codes: []};
    const premixedAlcoholicDrinksObject: any = _.find(this.categories, c => c.type === 'PREMIXED_ALCOHOLIC_DRINKS') || {codes: []};
    const vcsCodes: any[] = spiObject.codes.concat(vinLiqueurObject.codes, vinEffervescentObject.codes,
      vinTranquilleObject.codes, premixedAlcoholicDrinksObject.codes);
    return {
      vcsCodes,
      spiObject,
      vinLiqueurObject,
      vinEffervescentObject,
      vinTranquilleObject,
      premixedAlcoholicDrinksObject
    };
  }

  public isVcsCategory(codeGpc: string) {
    const classificationObject = this.vcsClassification();
    return {
      isVcsCategory: classificationObject.vcsCodes.map((item) => item.code).includes(codeGpc),
      isSpi: classificationObject.spiObject.codes.map((item) => item.code).includes(codeGpc),
      isWine: classificationObject.vinLiqueurObject.codes.map((item) => item.code).includes(codeGpc) || classificationObject.vinEffervescentObject.codes.map((item) => item.code).includes(codeGpc) ||
        classificationObject.vinTranquilleObject.codes.map((item) => item.code).includes(codeGpc) || classificationObject.premixedAlcoholicDrinksObject.codes.map((item) => item.code).includes(codeGpc)
    };
  }
}
