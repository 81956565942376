import {ActivatedRoute, Router} from '@angular/router';
import GtinUtils from '../../shared/utils/gtin';
import {Gtin} from '../../shared/models/index';
import {NotificationsSvc, CompanySvc, FeatureToggleSvc, UserSvc} from '../../shared/services/index';
import {BarcodeGenerationSvc, ProductsSvc} from '../services/index';
import {DashboardConst} from './dashboard.constants';
import {DateInput} from './dateInput.model';
import {Countries} from '../models/country.constants';
import {Spirits} from '../models/spirits.constants';
import {FormInputError} from './formInputError';
import {wineColorCodes} from './wineColor.constants';
import {Classification} from '../../shared/models/Classification.model';
import {ProductClassificationIndex} from './productClassification.constants';
import {SugarContentConstants} from './sugarContent.constants';
import {VintageSvc} from '../../shared/services/vintages.service';
import {SpiritStyles} from './spiritStyles.constants';
import {Allergen} from './Allergen.model';
import {VcsProductEditionFormModel} from './vcsProductEditionForm.model';
import {NetContent} from './NetContent.model';
import {BaseEditionUnityFormModel} from './baseEditionUnityForm.model';
import {finalize} from 'rxjs/operators';
import {ProductName} from './ProductName.model';

import * as _ from 'lodash';
import {Nutriment} from './nutriment.model';
import {NutrimentItem} from './nutrimentItem.model';
import {nutrimentItemsConst} from './nutrimentItems.constants';
import {nutrimentServingSizeConst} from './nutrimentServingSize.constants';
import {optionalNutrimentItemsConst} from './optionalNutrimentItems.constants';
import {nutrimentMeasurementShortUnitConst} from './nutrimentMeasurementShortUnit.constants';
import {nutrimentMeasurementPrecisionConst} from './nutrimentMeasurementPrecision.constants';
import {nutrimentMeasurementUnitConst} from './nutrimentMeasurementUnit.constants';

const moment = require('moment');

export class VcsEditionUnityForm extends BaseEditionUnityFormModel {

  public DECIMAL_PATTERN = '^([0-9]{1,2}(,\\d{1})?|100)$';
  public VOLUME_PATTERN = '^([0-9]{1,}(,\\d{1})?)$';

  public constants = DashboardConst;
  public initialBrand: string;
  public initialName: string;
  public submitting: boolean = false;
  public dateInput: DateInput = new DateInput('', '', '');
  public gpcCodes: Classification[];
  public countries = Countries;
  public wineColorCodes = wineColorCodes;
  public selectedCategorie = new Classification('', '', '');
  public spirits = Spirits;
  public spiritStyles = SpiritStyles;
  public productCategories = ProductClassificationIndex;
  public sugarContentConstants = SugarContentConstants.sparklingWine;
  public attributesToUpdate = {};
  public requiredNutrimentItems = nutrimentItemsConst;
  public nutrimentServingSize = nutrimentServingSizeConst;
  public optionalNutrimentItems = optionalNutrimentItemsConst;
  public measurementShortUnit = nutrimentMeasurementShortUnitConst;
  public measurementPrecision = nutrimentMeasurementPrecisionConst;
  public measurementUnit = nutrimentMeasurementUnitConst;


  constructor(public productsSvc: ProductsSvc,
              public _router: Router,
              public route: ActivatedRoute,
              public featureToggle: FeatureToggleSvc,
              public barcodeGenerationSvc: BarcodeGenerationSvc,
              public notifsSvc: NotificationsSvc,
              public companySvc: CompanySvc,
              public vintageSvc: VintageSvc,
              public userSvc: UserSvc) {
    super(productsSvc,
      _router,
      route,
      featureToggle,
      barcodeGenerationSvc,
      notifsSvc,
      companySvc,
      vintageSvc,
      userSvc);
    // this.productForm = new VcsProductEditionFormModel(new Gtin('', '', '', false), new FormInputError('', ''), new FormInputError('', ''),);
    this.productForm = new VcsProductEditionFormModel(new Gtin('', '', '', false, false),
      new FormInputError('', ''), new FormInputError('', ''), '', [new ProductName('', 'fr')], '', '', '', '',
      [], [], '', '', 0, [''], '', '', true, 0, '', '', '', '', -1, {}, '', '', '', {}, [], '',
      '', '', '', true, '', [], [], '', new Nutriment('100',
        'MLT', null, this.resetNutrimentItems()), '', false);

  }

  public onDateChange(date: string): void {
    if (date.match(/[a-z]/g)) {
      [this.dateInput.status, this.dateInput.message] = ['warning', `La date doit être au format JJ/MM/AAAA`];
      return;
    }

    const pattern = /\d{1,2}\/\d{1,2}\/\d{4}/g;
    if (date.match(pattern)) {
      const momentDate = moment(date, 'DD/MM/YYYY');
      if (!momentDate.isValid()) {
        [this.dateInput.status, this.dateInput.message] = ['error', `La date saisie n'est pas valide`];
        return;
      }
      if (momentDate.isAfter(moment())) {
        [this.dateInput.status, this.dateInput.message] = ['error', `La date saisie doit être antérieure à aujourd'hui`];
        return;
      }
      this.dateInput.status = '';
      return;
    }

    [this.dateInput.status, this.dateInput.message] = ['', ''];
  }

  public loadProduct(id: string) {
    this.productsSvc.getProduct(id)
      .subscribe(
        product => {
          _.assign(this.productForm, product);
          this.productForm.gtin = GtinUtils.constructGTINObject(product.gtin, product.prefix);
          // just to display target market label
          this.productForm.targetMarketLabel = this.getTargetMarketLabel(this.productForm.targetMarket);

          this.productForm.alcoholDegree = this.productsSvc.getFormatedAlcoholDegree(product.alcoholDegree ?? '');
          // @ts-ignore
          this.productForm.netContents = [new NetContent(this.productsSvc.getFormatedVolume(_.get(_.head(product.netContents), 'netContent', '')), 'CLT')];
          this.productForm.allergen = this.formatAllergen(this.productForm.allergen);
          // Saving values that should not be changed
          this.initialBrand = product.brand;
          this.initialName = product.name;
          this.productForm.nutriment.items = this.productsSvc.formatNutrimentItems(this.productForm.nutriment.items);

          if (_.isUndefined(product.codeGpc) || _.isEmpty(product.codeGpc)) {
            this.productForm.codeGpc = '0';
          }
          if (this.featureToggle.canSeeVcs()) {
            this.getClassification();
            this.initSugarContentList();
          }

        },
        err => {
          if (err.status === 404) {
            this._router.navigate(['/dashboard']);
            this.notifsSvc.productNotFound();
          }
        }
      );
  }

  public formatAllergen(allergens: any): Allergen {
    for (const key of Object.keys(allergens)) {
      allergens[key] = _.isBoolean(allergens[key]) ? allergens[key] : allergens[key].toLowerCase() === 'true';
    }
    return allergens;
  }

  public getTargetMarketLabel(code: string) {
    const targetMarket: any = _.find(Countries, function(targetMarketItem) {
      return targetMarketItem.code === code;
    }) || {};
    return targetMarket.wording || '';
  }

  public cancelCreation() {
    this._router.navigate(['/dashboard']);
  }

  public submitForm() {
    if (!this.submitting) {
      this.submitting = true;

      if (this.productForm.codeGpc !== undefined && this.productForm.codeGpc === '0') {
        this.productForm.codeGpc = '';
      }

      this.attributesToUpdate['name'] = this.productForm.name;
      this.attributesToUpdate['brand'] = this.productForm.brand;
      this.attributesToUpdate['mpn'] = this.productForm.mpn;
      this.attributesToUpdate['sku'] = this.productForm.sku;
      this.attributesToUpdate['subBrand'] = this.productForm.subBrand;
      this.attributesToUpdate['description'] = this.productForm.description;
      this.attributesToUpdate['webLink'] = this.productForm.webLink;
      this.attributesToUpdate['completionLevel'] = this.productForm.completionLevel;
      this.attributesToUpdate['images'] = this.productForm.images;
      this.attributesToUpdate['codeGpc'] = this.productForm.codeGpc;
      this.attributesToUpdate['alcoholDegree'] = this.productForm.alcoholDegree;
      this.attributesToUpdate['netContents'] = this.productForm.netContents;
      this.attributesToUpdate['productCountry'] = this.productForm.productCountry;
      this.attributesToUpdate['vintage'] = this.productForm.vintage;
      this.attributesToUpdate['allergen'] = this.productForm.allergen;
      this.attributesToUpdate['ageOfSpirit'] = this.productForm.ageOfSpirit;
      this.attributesToUpdate['vintageName'] = this.productForm.vintageName;
      this.attributesToUpdate['container'] = this.productForm.container;
      this.attributesToUpdate['certifications'] = this.productForm.certifications;
      this.attributesToUpdate['varietals'] = this.productForm.varietals;
      this.attributesToUpdate['aoc'] = this.productForm.aoc;
      this.attributesToUpdate['gis'] = this.productForm.gis;
      this.attributesToUpdate['spiritStyle'] = this.productForm.spiritStyle;
      this.attributesToUpdate['isPrivate'] = this.productForm.isPrivate;
      this.attributesToUpdate['adhereToTheSectoralAgreement'] = this.productForm.adhereToTheSectoralAgreement;
      this.attributesToUpdate['imagesAddedByUrl'] = this.constructImagesAddedByUrl();
      this.attributesToUpdate['targetMarket'] = this.productForm.targetMarket;
      this.attributesToUpdate['productNames'] = this.productForm.productNames;
      this.attributesToUpdate['ingredientStatement'] = this.productForm.ingredientStatement;
      this.attributesToUpdate['nutriment'] = this.productForm.nutriment;
      this.attributesToUpdate['preparationStateCode'] = this.productForm.preparationStateCode;
      this.attributesToUpdate['adhereToRegulatoryAgreement'] = this.productForm.adhereToRegulatoryAgreement;

      if (this.productForm.productType === 'BUNDLE'){
        this.attributesToUpdate['productResellerType'] = 'BUNDLE';
      }
      this.productsSvc.updateProduct(this.id, this.attributesToUpdate, this.productForm.imagesToUpload)
        .pipe(finalize(() => this.submitting = false))
        .subscribe(
          () => {
            if (!this.userSvc.user.haveSeenProductNames) {
              this.userSvc.updateUser({haveSeenProductNames: true});
            }
            this._router.navigate(['/dashboard/product/view/' + this.id]);
          },
          err => {
            switch (err.error.type) {
              case 'IMPORT_IN_PROGRESS':
                this.notifsSvc.importInProgress();
                break;
              case 'PRODUCT_VALIDATION_FAILED' :
                this.notifsSvc.updateProductValidation();
                break;

              default:
                console.error(err);
                break;
            }
          }
        );
    }
  }

  public deleteProduct() {
    if (!this.submitting) {
      this.submitting = true;

      this.productsSvc.deleteProduct(this.id)
        .pipe(finalize(() => this.submitting = false))
        .subscribe(
          () => this._router.navigate(['/dashboard'], {queryParams: {actionInProgress: true}}),
          // @ts-ignore
          (err: any) => {
            if (err.status === 404) {
              return this._router.navigate(['/dashboard']);
            }
            if (err.status === 401) {
              return;
            }

            switch (err.error.type) {
              case 'IMPORT_IN_PROGRESS':
                this.notifsSvc.importInProgress();
                break;

              default:
                this.notifsSvc.serverError();
                break;
            }
          }
        );
    }
  }

  public generateBarcode() {
    this.barcodeGenerationSvc.generateBarcode(this.id);
  }

  public onAlcoholDegreeChange(alcoholDegree: string) {
    const decimalRegex = new RegExp(this.DECIMAL_PATTERN);
    if (!decimalRegex.test(alcoholDegree)) {
      this.productForm.alcoholDegreeError = new FormInputError('warning', this.constants.createForm.errors.alcoholDegreePattern);
    } else {
      this.productForm.alcoholDegreeError = new FormInputError('valid', '');
    }
  }

  public onVolumeChange(volume: string) {
    const decimalRegex = new RegExp(this.VOLUME_PATTERN);
    if (decimalRegex.test(volume)) {
      this.productForm.volumeError = new FormInputError('valid', '');
    } else {
      this.productForm.volumeError = new FormInputError('warning', '');
    }
  }

  public onGpcChange() {
    this.getClassification();
    this.productForm.allergen = {};

    this.initSugarContentList();
  }

  public prepareClassificationList(classifications: Classification[]) {
    this.gpcCodes = [new Classification('nothing', '', '')];
    this.gpcCodes = this.gpcCodes.concat(classifications);
  }

  public initSugarContentList() {
    if (this.isProductSparklingWine()) {
      this.sugarContentConstants = SugarContentConstants.sparklingWine;
    }

    if (this.isNotProductSparklingWine() || this.isPremixedAlcoholicDrinks()) {
      this.sugarContentConstants = SugarContentConstants.stillWine;
    }

    const sugarContentFound = this.sugarContentConstants.filter(sugarContent => sugarContent.code === this.productForm.sugarContent);
    if (sugarContentFound.length === 0) {
      this.productForm.sugarContent = '';
    }
  }

  public isProductVcs() {
    return this.selectedCategorie.categorie === this.productCategories.SPI || this.selectedCategorie.categorie === this.productCategories.VIN;
  }


  public isProductWine() {
    return this.selectedCategorie.categorie === this.productCategories.VIN;
  }

  public getClassification() {
    // @ts-ignore
    this.selectedCategorie = _.find(this.gpcCodes, {code: this.productForm.codeGpc});
  }

  public isProductSparklingWine() {
    const gpcCodeList = [
      this.productCategories.SPARKLING_WINE_GPC_CODE,
      this.productCategories.SPARKLING_WINE_GPC_CODE_NOTALCOHOLIC
    ];
    return gpcCodeList.includes(this.productForm.codeGpc);
  }

  public isNotProductSparklingWine() {
    const gpcCodeList = [
      this.productCategories.STILL_WINE_GPC_CODE,
      this.productCategories.STILL_WINE_GPC_CODE_NOTALCOHOLIC,
      this.productCategories.LIQUEUR_WINE_GPC_CODE,
      this.productCategories.PREMIXED_ALCOHOLIC_GPC_CODE,
      this.productCategories.SPIRIT_GPC_CODE,
      this.productCategories.LIQUEUR_WINE_GPC_CODE
    ];
    return gpcCodeList.includes(this.productForm.codeGpc);
  }
  public isPremixedAlcoholicDrinks() {
    return this.productForm.codeGpc === this.productCategories.PREMIXED_ALCOHOLIC_GPC_CODE;
  }

  public isProductAnyWine() {
    return this.isProductSparklingWine() || this.isNotProductSparklingWine();
  }

  public onVisibilityChange(value) {
    this.productForm.isPrivate = !value;
  }

  public findWordingFromCode(constants: Array<{ wording: string, code: any }> = [], code: any = '') {
    const c = _.find(constants, element => element.code.toString() === code) || {
      wording: ''
    };
    return c.wording;
  }

  public resetFormProductWitBaseAttribute() {
    this.productForm = new VcsProductEditionFormModel(this.productForm.gtin, new FormInputError('', ''), new FormInputError('', ''), this.productForm.name, this.productForm.productNames, this.productForm.brand, this.productForm.mpn, this.productForm.sku,
      this.productForm.subBrand, this.productForm.imagesAddedByUrl, this.productForm.imagesToUpload, this.productForm.description, this.productForm.webLink, this.productForm.completionLevel, this.productForm.targetMarket, this.productForm.codeGpc,
      this.productForm.targetMarketLabel, this.productForm.adhereToTheSectoralAgreement, this.productForm.companyId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.productForm.isPrivate, this.productForm.category, this.productForm.netContents, this.productForm.images, null, null,
      null, this.productForm.adhereToRegulatoryAgreement);
  }

  public resetFormProductWitRegulatoryAttribute() {
    this.productForm = new VcsProductEditionFormModel(this.productForm.gtin, new FormInputError('', ''), new FormInputError('', ''), this.productForm.name, this.productForm.productNames, this.productForm.brand, this.productForm.mpn, this.productForm.sku,
      this.productForm.subBrand, this.productForm.imagesAddedByUrl, this.productForm.imagesToUpload, this.productForm.description, this.productForm.webLink, this.productForm.completionLevel, this.productForm.targetMarket, this.productForm.codeGpc,
      this.productForm.targetMarketLabel, this.productForm.adhereToTheSectoralAgreement, this.productForm.companyId, undefined, undefined, undefined, undefined, undefined, this.productForm.allergen, undefined, undefined,
      undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.productForm.isPrivate, this.productForm.category, this.productForm.netContents, this.productForm.images, this.productForm.ingredientStatement, this.productForm.nutriment,
      this.productForm.preparationStateCode, this.productForm.adhereToRegulatoryAgreement);
  }

  // @ts-ignore
  public constructImagesAddedByUrl() {
    const toReturn: any[] = [];
    this.productForm.imagesAddedByUrl.forEach((imagesAddedByUrl: any) => {
      toReturn.push(imagesAddedByUrl.url);
    });
    return toReturn;
  }

  public resetNutrimentItems(): NutrimentItem[] {
    const toReturn: NutrimentItem[] = [];
    this.requiredNutrimentItems.forEach(nutrimentItem => {
      const item = new NutrimentItem(nutrimentItem.code, 'APPROXIMATELY', null,
        null, nutrimentItem.measurementUnitCode);
      toReturn.push(item);
    });

    return toReturn;
  }

  public findNutrimentWording(code: string, measurementUnitCode: string): string {
    let nutriment: any = _.find(this.requiredNutrimentItems, c => c.code === code && c.measurementUnitCode === measurementUnitCode) || {};
    if (_.isEmpty(nutriment)) {
      nutriment = _.find(this.optionalNutrimentItems, c => c.code === code && c.measurementUnitCode === measurementUnitCode) || {};
    }
    return nutriment.wording || '';
  }

  public findMeasurementPrecisionWording(code: string): string {
    const measurementPrecision: any = _.find(this.measurementPrecision, c => c.code === code) || {};
    return measurementPrecision.wording;
  }

  public findServingSizeWording(code: string): string {
    const returnedObject: any = _.find(this.nutrimentServingSize, c => c.code === code) || {};
    return `pour ${returnedObject.wording}`;
  }


  public findMeasurementShortUnitWording(code: string): string {
    const measurementShortUnit: any = _.find(this.measurementShortUnit, c => c.code === code) || {};
    return measurementShortUnit.wording || '';
  }

  public onQuantityContainedChange(value: number, index: number) {
    const decimalRegex = new RegExp(this.DECIMAL_PATTERN);
    if (value && !decimalRegex.test(value.toString())) {
      this.productForm.nutriment.items[index].quantityContained = null;
    }
  }

  public onOptionalQuantityContainedChange(value: string, index: number) {
    const decimalRegex = new RegExp(this.DECIMAL_PATTERN);
    if (value && !decimalRegex.test(value)) {
      this.productForm.nutriment.items[index].optionalQuantityContained = null;
    }
  }

  public findMeasurementUnitWording(code: string): string {
    const measurementUnit: any = _.find(this.measurementUnit, c => c.code === code) || {};
    return measurementUnit.wording || '';
  }
}
